.show-in-mobile{
    display: none;
}


@media (max-width: 576px) {
    .show-in-mobile{
        display: flex !important;
        flex-direction: column;
    }
    .hide-in-mobile{
        display: none;
    }
}



.newsletter {
    background: #000;
}

.newsletter .newsletter-area {
    padding: 30px 0px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
}

.newsletter .newsletter-area .first {
    >h6 {
        color: var(--White, #FFF);

        /* Text xl/Medium */
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
        /* 150% */
    }

    >p {
        color: #B3B3B3;

        /* Text md/Normal */
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 150% */
    }
}

.newsletter .newsletter-area .second input {
    width: 400px;
    padding: 12px 16px;
    align-items: center;
    gap: 12px;
    background-color: #000;
    color: #FFF;
    border-radius: 8px;
    border: 1px solid #707070;

    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.newsletter .newsletter-area .second button {
    /* display: flex; */
    margin: 0px 10px;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid var(--primary-blue, #00AEEF);
    background-color: #000;
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: var(--White, #FFF);
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
}



@media (max-width: 576px) {
    .newsletter .newsletter-area {
        padding: 30px 20px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;
    }

    .newsletter .newsletter-area .first {}

    .newsletter .newsletter-area .second .input-box {
        width: 200px;
    }

}

.main-footer {
    background: #181818;
}

.main-footer .first-container {
    padding: 50px 0px;
    display: flex;
    /* flex-direction: row; */
    /* align-items: center; */
    justify-content: space-around;
}


.main-footer .first-container p {
    color: #B3B3B3;
    /* Text md/Normal */
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
}

.main-footer .first-container .first {
    width: 40%;
}

.main-footer .first-container .first img {
    width: 260px;
    height: 120px;
}

.main-footer .first-container h5 {
    width: 80%;
    white-space: normal;
    text-overflow: initial;
    color: #B3B3B3;

    /* Text md/Normal */
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
}

.main-footer .first-container h6 {
    color: #F0F0F0;

}
.main-footer .first-container .third h6{
    padding-bottom: 5px;
}
.main-footer .first-container .second h6{
    padding-bottom: 5px;
}
.main-footer .first-container .fourth h6{
    padding-bottom: 5px;
}

.main-footer hr {
    background: #00AEEF;
}

.main-footer .second-container {
    padding-bottom: 40px;
}

.main-footer .second-container h5 {
    color: var(--Gray-400, #98A2B3);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.main-footer .second-container div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main-footer .second-container .social {
    display: flex;
    align-items: center;
}

.main-footer .second-container .social img {
    margin: 0px 10px;
}


@media (max-width: 576px) {
    .main-footer .first-container {
        flex-direction: row;
    }
    .main-footer .show-in-mobile{
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;
       >h5{
        color: #F0F0F0;
        margin: 10px;
       }
    }
    .main-footer .show-in-mobile img{
        width: 90%;
        padding: 10px 0px;
    }
    .main-footer .first-container .first {
        width: 100%;
        align-items: center;
        justify-content: space-around;
        >img {
            width: 90%;
        }

        >h5 {
            padding: 20px;
            color: white;
        }
    }

    .main-footer .first-container .flex {
        display: flex;
        justify-content: space-around;
    }

    .main-footer .second-container .social-box {
        flex-direction: column;
    }
}

.main-footer a{
    color: #FFF !important;
    text-decoration: none;
}