
@media (max-width: 576px) {
    .hide-in-mobile{
        display: none !important;
    }

}

.bg-color-f0f0f0 {
    background-color: #F0F0F0;
    margin-top: 40px 0px;
    padding: 30px 0px;
    border-top: 1px solid #F6F6F6;
    border-bottom: 1px solid #F6F6F6;
}


/* First Section  */
.left-card-box h1 {
    color: #181818;
    /* font-family: Raleway; */
    font-size: 4rem;
    font-style: normal;
    font-weight: 600;
    line-height: 84px;
    /* 131.25% */
}

.left-card-box h4 {
    margin-top: 20px;
    color: #181818;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    width: 70%;
    white-space: normal;
    text-overflow: initial;
}

.left-card-box button {
    border-radius: 8px;
    border: 2px solid var(--primary-blue, #00AEEF);
    background: #FFF;
    padding: 10px 30px;
    margin-top: 40px;
    color: #181818;
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;
}

.right-card-box {
    margin: 50px 0px;
}

/* .circle-parent-container{
    background-color: #3498db;
} */
.circle-container {
    display: flex;
    align-items: center;
    margin-top: 30px;
    /* justify-content: center; */
}

.circle {
    width: 60px;
    height: 60px;
    background-color: #3498db;
    border-radius: 50%;
    margin-right: -30px;
    position: relative;
    z-index: 1;
    border: 2px solid var(--primary-green, #B9E500);
    background: url(<path-to-image>), lightgray 50% / cover no-repeat;
}

.circle:nth-child(2) {
    width: 60px;
    height: 60px;
    background-color: #e74c3c;
    z-index: 2;
    margin-right: -30px;
    border: 2px solid var(--primary-green, #B9E500);
    background: url(<path-to-image>), lightgray 50% / cover no-repeat;
}

.circle:nth-child(3) {
    width: 60px;
    height: 60px;
    background-color: #2ecc71;
    z-index: 3;
    border: 2px solid var(--primary-green, #B9E500);
    background: url(<path-to-image>), lightgray 50% / cover no-repeat;
}

.circle-text {
    color: var(--Gray-500, #667085);
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    margin-left: 40px;
}

.main-first-section {
    min-height: 100vh;
    padding-top: 50px;
}


@media (max-width: 576px) {

    .main-first-section {
        min-height: 80vh;
        /* width: "100%"; */
    }

    .left-card-box {
        text-align: center;

        >h4 {
            width: 100%;
        }
    }

}

.second-section {
    background-color: #F6F6F6;
}

.banner {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 160px;
    background-color: #000;
    padding: 0px 80px;
}

.banner>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 10px;
}

.banner>div h4 {
    color: #FFF;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.banner>div h6 {
    color: #FFF;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 112.5% */
}

.banner>span {
    margin: 0 10px;
    font-size: 2.5rem;
    /* font-weight: bold; */
    color: #B9E500;
    /* Adjust the color as needed */
}

@media (max-width: 576px) {

    .banner {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 100px;
        background-color: #000;
        padding: 0px 20px;
    }

    .banner>div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 0 5px;
    }

    .banner>div h4 {
        color: #FFF;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }

    .banner>div h6 {
        color: #FFF;
        font-size: 0.5rem;
        font-style: normal;
        font-weight: 200;
        line-height: 9px;
        /* 112.5% */
    }

    .banner>span {
        margin: 0 5px;
        font-size: 1.25rem;
        /* font-weight: bold; */
        color: #B9E500;
        /* Adjust the color as needed */
    }
}





/* Company Banner  */


.company-banner {
    text-align: center;
    min-height: 210px;
    background: #FFF;
    box-shadow: -5px -5px 10px 0px rgba(0, 0, 0, 0.25) inset, 5px 5px 10px 0px rgba(0, 0, 0, 0.25) inset;
}

.company-banner>div {
    display: flex;
    justify-content: space-around;
}

.company-banner>p {
    color: #B9E500;
    /* Adjust the color as needed */
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 30px 0px 20px;
}

/* Third Section */

.third-section h2 {
    margin: 0px 0px 50px;
    text-align: center;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.third-section .content-area {
    display: flex;
    flex-direction: row;

}


.third-section .left-box {
    margin: 0px 30px;
}

.third-section .right-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}


.third-section .card-box-head {
    line-height: 1;
}

.third-section .right-box>div {
    flex: 0 0 calc(50% - 20px);
    padding: 25px;
    margin-bottom: 40px;
    border-radius: 8px;
    border: 2px solid var(--primary-green, #B9E500);
    background: #FFF;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset, -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset;
}

.third-section .right-box div div {
    display: flex;
    justify-content: space-between;
}

.third-section .right-box>div div div p {
    /* display: flex;
    align-items: center;
    line-height: 0; */
    color: #181818;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1;
}

.third-section .right-box>div h4 img {
    margin-right: 10px;
    max-width: 100px;
    max-height: 83px;
    flex-shrink: 0;
}

.third-section .right-box p {
    margin: 0;
}

.third-section .card-box {
    height: 280px;
}

.third-section .card-box:hover {
    border-radius: 8px;
    border: 3px solid var(--primary-green, #B9E500);
    background: #D8FFAC;
    box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;
    >.card-box-content {
        width: 100%;
        white-space: normal;
        text-overflow: initial;
    }

    >.card-box-content p {
        padding-top: 10px;
        color: #000000;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

}

.third-section .card-box-content {
    width: 70%;
    /* overflow: hidden; */
    white-space: normal;
    text-overflow: initial;
    /* transition: width 0.5s ease;  */
}

.third-section .card-box-content p {
    color: #717171;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

@media (max-width: 576px) {
    .third-section .content-area {
        display: flex;
        flex-direction: column-reverse;
    }

    .third-section .right-box>div {
        flex: 0 0 calc(100% - 20px);
        padding: 25px;
        margin: auto;
        margin-bottom: 40px;
        border-radius: 16px;
        border: 2px solid var(--primary-green, #B9E500);
        background: #FFF;
        box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;
    }

    .third-section .card-box-content {
        width: 100%;
        margin-top: 20px;
    }

    .third-section .left-box {
        display: none;
    }
}

/* Fourth Section  */
.fourth-section {
    /* background-color: #F6F6F6; */
}

.fourth-card-box {
    display: flex;
    margin-bottom: 80px;
}

@media (max-width: 576px) {
    .fourth-card-box {
        display: flex;
        flex-direction: column;
        margin-bottom: 80px;
    }

    .fourth-card-content-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 20px;

        >p {
            font-size: 1.25rem !important;
            width: 100% !important;
        }

        >h3 {
            font-size: 2rem !important;
        }
    }

    .fourth-card-content-box-image {
        display: flex;
        justify-content: center;
    }
}

.fourth-card-content-box-image img {
    width: 90%;
}

.fourth-card-box>div h3 {
    width: 80%;
    /* overflow: hidden; */
    white-space: normal;
    text-overflow: initial;
    color: #000;
    /* font-family: Raleway; */
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    >.first-span {
        color: var(--primary-blue, #00AEEF);
        font-family: Raleway;
        font-size: 3rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    >.second-span {
        color: var(--primary-green, #B9E500);
        font-family: Raleway;
        font-size: 3rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}

.fourth-card-box>div p {
    margin-top: 10px;
    width: 90%;
    /* overflow: hidden; */
    white-space: normal;
    text-overflow: initial;
    color: #181818;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.fourth-card-box>div button {
    margin-top: 40px;
    border-radius: 8px;
    border: 2px solid var(--primary-blue, #00AEEF);
    background: #FFF;


    color: #181818;
    text-align: center;
    padding: 10px 30px;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;
}

.fourth-card-content-box {
    margin: 0px 20px;
}

/* Fifth Section  */

.fifth-section .courses-area .details-box {
    margin: 40px 0px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 100%;
}

.fifth-section .courses-area .details-box .box {
    display: flex;

    >div {
        >p {
            margin-bottom: 10px;
        }
    }
}

.fifth-section .courses-area .details-box .box div {
    margin-left: 10px;
}

.courses-box {
    float: right;
    border-radius: 16px;
    background: #FFF;
    box-shadow: -3px -3px 5px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 5px 0px rgba(0, 0, 0, 0.25) inset;
    max-width: fit-content;
    padding: 10px;
}

.courses-button {
    border-radius: 8px;
    color: #070707;
    border: none;
    font-size: 1.125rem;
    background: #FFF;
    font-style: normal;
    padding: 15px 10px;
    font-weight: 600;
    line-height: 28px;
    /* 155.556% */
}

.courses-button.active {
    border-radius: 8px;
    border: none;
    background: var(--primary-green, #B9E500);
    box-shadow: -3px -3px 10px 0px rgba(16, 24, 40, 0.05) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;
    color: #070707;
    padding: 15px 10px;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    /* 155.556% */
}

.courses-area h2 {
    color: #000;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
}

.courses-area .details-box {
    max-width: 100%;
}

/* .courses-parent-box{
    max-width: 100%;
   
} */
.course-parent-box {
    margin-top: 160px;
    /* background-color: #00AEEF;
    display: flex;
    width: calc(33% - 20px); */
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
}

.course-box {
    border-radius: 16px;
    border: 1px solid rgba(55, 58, 63, 0.30);
    background: #FFF;
    margin: 0px 20px 50px;
    width: calc(33.3333% - 40px);

}

.course-box div {
    margin: 20px 10px;
}


.course-box div div span {
    border-radius: 8px;
    background: var(--secondary-green, #D8FFAC);
    padding: 5px 10px;
}

.course-box img {
    width: calc(100% - 20px);
    margin: 10px 10px 10px 10px;
    border-radius: 16px 16px 0px 0px;
    /* background: url(<path-to-image>), lightgray 50% / cover no-repeat; */
}

.course-box h4 {
    color: #000;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    /* 112.5% */
}

.course-box h5 {
    color: #000;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    /* 125% */
}

.course-box-mentor {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 !important;
}

.course-box-mentor div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
    margin: 0 !important;
    min-width: 50%;
    padding-right: 10px;
    border-right: 1px solid black;
}

.course-box-mentor img {
    margin: 0 !important;
    height: 50px;
    width: 50px;
}

.course-box-mentor div h6 {
    color: #000;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    /* 100% */
}

.course-box-mentor div p {
    color: #000;
    font-size: 0.825rem;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
}

.course-box-mentor h5 {
    color: #000;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    /* 150% */
}

.course-box-footer {
    margin: 0 !important;
    display: flex;
    justify-content: space-between;
}

.explore-button-div {

    margin-bottom: 50px;
}

.explore-button-div button {
    border: none;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    border-radius: 8px;
    background: var(--primary-green, #B9E500);
    box-shadow: -3px -3px 5px 0px rgba(16, 24, 40, 0.05) inset, 3px 3px 5px 0px rgba(0, 0, 0, 0.25) inset;
    display: flex;
    width: 304px;
    height: 72px;
    padding: 16px 28px;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

@media (max-width: 576px) {

    .fifth-section .courses-area .details-box .box {
        display: flex;
        justify-content: space-around;

        >img {
            height: 30px;
            width: 30px;
        }

        >div {
            >p {
                font-size: 8px;
                margin-bottom: 0px;
            }

            >h6 {
                font-size: 12px;
            }
        }
    }

    .fifth-section .courses-parent-box .courses-button {
            font-size: 0.75rem;
            background: #FFF;
            padding: 5px 5px;

        }
        .fifth-section .courses-button.active {
            border-radius: 8px;
            border: none;
            background: var(--primary-green, #B9E500);
            box-shadow: -3px -3px 10px 0px rgba(16, 24, 40, 0.05) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;
            color: #070707;
            padding: 5px 5px;
            font-size: 0.75rem;

        }

        .fifth-section .course-parent-box{
            display: flex;
            flex-direction: column;
        }
    
        .fifth-section .course-box {
            border-radius: 16px;
            border: 1px solid rgba(55, 58, 63, 0.30);
            background: #FFF;
            margin: 0px 10px 30px;
            width: calc(100% - 20px);
        
        }

}






/* .play{
    padding: 5px 5px;
    border-radius: 50%;
} */


/* Sixth Section  */

.sixth-section .discover-heading {
    margin: 40px 0px;
    text-align: center;
}

.sixth-section .discover-heading h3 {
    color: #000;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.sixth-section .discover-card-box {
    text-align: center;
    align-items: center;
    justify-content: center;
    /* display: flex; */
}

.sixth-section .discover-card-box {
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 100px;
}

.sixth-section .discover-card-box .disc-card {
    padding: 10px;
    height: 430px;
    max-width: 320px;
    border-radius: 20px;
    border: 2px solid var(--primary-green, #B9E500);
    background: rgba(216, 255, 172, 0.30);
    box-shadow: -3px -3px 10px 0px rgba(0, 27, 111, 0.20) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;
}

.sixth-section .discover-card-box div img {
    height: 180px;
    margin: 30px auto;
}

.sixth-section .slick-dots li button:before {
    font-size: 1.25rem;
    line-height: 20px;
    margin-top: 70px;
    left: -30px;
    color: var(--primary-green, #B9E500);
}



.sixth-section .slick-dots li button:focus {
    color: var(--primary-green, #B9E500);
}

.sixth-section .discover-card-box div h6 {
    color: #000;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.sixth-section .discover-card-box div p {
    color: #45494E;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media (max-width: 576px) {
    .sixth-section .discover-card-box {
        margin: 5px !important;
    }

    .sixth-section .discover-card-box .disc-card {
        margin: 10px;
        width: 400px !important;
        overflow: hidden;
    }

    .sixth-section .slick-dots li button:before {
        left: 0px;
    }

    .sixth-section .slick-prev:before,
    .slick-next:before {
        color: #B9E500;
    }
}






/* Seventh Section  */

.testimonial-section .testimonial-heading {
    margin: 40px 0px;
}

.testimonial-section .testimonial-heading div {}

.testimonial-section .testimonial-heading h6 {
    width: 60%;
    margin: 0px auto;
    white-space: normal;
    text-overflow: initial;
    color: #070707;
    text-align: center;
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    line-height: 62px;
    /* 129.167% */

}

.testimonial-section .testimonial-card {
    /* margin: 0px 30px; */
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    height: 330px;
    /* Set a fixed height for vertical scrolling */
    overflow-y: auto;
    /* Enable vertical scrolling */
}

.testimonial-card::-webkit-scrollbar {
    width: 10px;
    /* Set the width of the scrollbar */
}

.testimonial-card::-webkit-scrollbar-thumb {
    background-color: #00AEEF;
    /* Set the color of the scrollbar thumb */
    border-radius: 4px;
    /* Optional: Add some border-radius for a rounded appearance */
}


.testimonial-section .testimonial-card>div {
    flex: 0 0 calc(33.3333% - 20px);
    margin: 10px;
    padding: 20px;
    border-radius: 16px;
    background: var(--primary-blue, #00AEEF);
    box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;
    width: 420px;
    height: 310px;
}

.testimonial-section .testimonial-card p {
    margin-top: 20px;
    color: #FFF;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 137.5% */
}

.testimonial-section .testimonial-card .profile {
    margin-top: 20px;
    display: flex;
    align-items: center;
}

.testimonial-section .testimonial-card .profile div {
    margin: 20px;
}

.testimonial-section .testimonial-card .profile div h4 {
    color: #FFF;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    /* 122.222% */
}

.testimonial-section .testimonial-card .profile div h5 {
    color: #070707;
    font-size: 0.825rem;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 157.143% */
}

@media (max-width: 576px) {
    .testimonial-section .testimonial-heading h6 {
        width: 90%;
    }

    .testimonial-section .testimonial-card>div {
        flex: 0 0 calc(100% - 20px);
        padding: 20px;
        height: 310px;
    }

}

/* Eigth Section  */
.eigth-section .chat-box {
    margin: 70px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.eigth-section .chat-box .contact {
    height: 600px;
    width: calc(50% - 100px);
    padding: 50px;
    border-radius: 16px;
    background: linear-gradient(204deg, #D8FFAC 20.1%, rgba(0, 174, 239, 0.46) 84.77%);
    box-shadow: -3px -3px 5px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 5px 0px rgba(0, 0, 0, 0.25) inset;
}

.eigth-section .chat-box .contact h5 {
    color: #181818;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.56px;
}

.eigth-section .chat-box .contact p {
    color: #707070;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.eigth-section .chat-box .contact .flex {
    display: flex;
    margin: 30px 10px;
}

.eigth-section .chat-box .contact .flex div {
    margin: 0px 10px;
    line-height: 1;
}

.eigth-section .chat-box .contact .flex div h6 {
    line-height: 1;
}

.eigth-section .chat-box .contact .social {
    display: flex;
    margin-top: 9rem;
}

.eigth-section .chat-box .contact .social div img {
    width: 18px;
    height: 18px;
    margin: 7px;
}

.eigth-section .chat-box .contact .social div {
    margin: 10px;
    padding: 0px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.40);
}

.eigth-section .chat-box .connect {
    height: 600px;
    width: calc(50% - 100px);
    padding: 0px 50px 50px;
    > div{
        height: 100%;
    }
}

.eigth-section .chat-box .connect label {
    margin: 10px 0px;
    color: #C7C7C7;
    font-size: 0.825rem;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
}

.eigth-section .chat-box .connect button {
    bottom: 0;
    width: 100%;
    border-radius: 8px;
    border: none;
    background: var(--primary-green, #B9E500);
    box-shadow: -3px -5px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;

    display: flex;
    /* width: 414px; */
    /* height: 48px; */
    color: #000;
    padding: 14px 24px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    font-weight: 500;
}

.rating-star i {
    color: #FFF;
    margin: 5px;
}

@media (max-width: 576px) {
    .eigth-section .chat-box {
        flex-direction: column;

        >.contact {
            min-width: 100%;
        }

        >.connect {
            min-width: 100%;
            padding: 20px 0px;
        }
    }
    .eigth-section .chat-box .contact .social {
        display: flex;
        margin-top: 5rem;
        justify-content: center;
    }

}