.coupons.open {
    display: block;
}

.coupons.close {
    display: none;
}

.existing-coupon-box {
    display: flex;
    flex-direction: row;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background for the blur effect */
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    width: 50%;
    padding: 20px;
    align-items: normal !important;
    gap: 10px !important;

    .close {
        margin-left: auto !important;
        margin: 10px;
        cursor: pointer;
    }

    .coupon-input-box {
        display: flex;
        flex-direction: row;
    }

    .wrong-hide{
        display: none;
    }

    >h4 {
        color: Grey;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1;
        margin-top: 20px;

    }

    .existing-coupons {
        border-radius: 5px;
        border: 1px solid lightgray;
        .grey {
            background-color: lightgrey;
        }
        .existing-coupon-box {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            
            >div {
                margin: 5px 10px;

                >h6 {
                    color: #000;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 1;
                }

                >p {
                    color: #000;
                    font-size: 1rem;
                    font-style: normal;
                    padding-top: 5px;
                    line-height: 0;
                }
            }

            >button {
                margin: 0px 10px;
            }
        }
    }
}

.apply-button {
    margin: 20px 40px;
    border-radius: 10px;
    background: var(--primary-blue, #00AEEF);
    padding: 10px 60px;
    color: #FFF;
    text-align: center;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: none;
}

.total-fee-amount{
    font-weight: 500;
    text-align: center;
    font-size: 20px;
    font-style: normal;
}