.first-about-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    height: 100%;

    >div {
        width: 70%;
        white-space: normal;
        text-overflow: initial;

        >h1 {
            color: #FFF;
            font-size: 4rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

        }

        >p {
            width: 70%;
            white-space: normal;
            text-overflow: initial;
            color: #FFF;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

}

.second-about-container {
    padding: 40px 0px;
    display: flex;
    flex-direction: row;

    .image-card {
        flex: 1;
        margin: 10px 30px;
        padding: 90px 30px;
        box-sizing: border-box;
        border-radius: 16px;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.80) 100%), url(<path-to-image>), lightgray 50% / cover no-repeat;
        height: 420px;

        >h6 {
            color: var(--primary-green, #B9E500);
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        >p {
            padding-top: 10px;
            color: #FFF;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    .image-card.top-gap {
        margin-top: 80px;
    }

}

.third-about-container {
    padding: 40px 0px;

    >h2 {
        color: #FFF;
        text-align: center;

        font-size: 3rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    >p {
        margin-top: 20px;
        color: #FFF;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}




.fourth-about-container {
    padding: 40px 0px;

    >h2 {
        color: #FFF;
        text-align: center;

        font-size: 3rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding-bottom: 40px;
    }
}


.fourth-about-container .about-cards {
    display: flex;
    flex-direction: row;

    .colorful-card {
        position: relative;
        flex: 1;
        margin: 10px;
        padding: 20px;
        box-sizing: border-box;
        overflow: hidden;

        /* Include padding in the width calculation */
        >h4 {
            color: #FFF;
            font-size: 2.1rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 20px;
        }

        >h3 {
            color: #FFF;
            font-size: 2rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        >p {
            color: #FFF;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 60px;
        }

        >img {
            position: absolute;
            bottom: 20px;
            left: 20px;
            max-width: 100%;
            height: auto;
        }
    }

    .colorful-card.card1 {
        border-radius: 16px;
        border: 1px solid #3F3C4D;
        background: rgba(0, 174, 239, 0.70);
    }

    .colorful-card.card2 {
        border-radius: 16px;
        border: 1px solid #3F3C4D;
        background: rgba(185, 229, 0, 0.70);
    }

    .colorful-card.card3 {
        border-radius: 16px;
        border: 1px solid #3F3C4D;
        background: rgba(102, 102, 102, 0.70);
    }

    .colorful-card.card4 {
        border-radius: 16px;
        border: 1px solid #313131;
        background: rgba(49, 49, 49, 0.70);
    }

    .colorful-card.card5 {
        border-radius: 16px;
        border: 1px solid #3F3C4D;
        background: rgba(19, 19, 19, 0.70);
    }
}

.black-input {
    background-color: #000;
    color: #707070;
}


@media screen and (max-width: 600px) {


    .first-about-container div {
        width: 100%;

        >h1 {
            text-align: center;
            font-size: 3rem !important;
        }

        >p {
            text-align: center;
            width: 100%;
        }
    }

    .second-about-container {
        flex-direction: column;

        .top-gap {
            margin-top: 10px !important;
        }
    }
    .third-about-container {
        >h2{
            font-size: 2.5rem !important;
        }
        >p{
            font-size: 1rem !important;
            padding: 0px 30px !important;

        }
    }
    .fourth-about-container{
        flex-direction: column !important;
        .about-cards{
            display: flex;
            flex-direction: column;
        }
        .colorful-card{
            min-width: 90% !important;
            margin: 10px;
        }
    }
}