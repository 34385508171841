
@media (max-width: 576px) {
    .show-in-mobile {
        display: flex !important;
    }

    .hide-in-mobile {
        display: none;
    }

}

.hide-in-web{
    display: none;
}
.hide{
    display: none !important;
}
.show{
    display: flex;
}




.sidebar-container {
    position: absolute;
    top: 50px;
    left: -280px;
    /* height: 100%; */
    overflow-x: hidden;
    transition: left 0.3s ease;
    z-index: 1000;
}

.sidebar-container.open {
    left: 0; 
}

/* Add any other styles you need for your sidebar */


/* Add any other styles you need for your sidebar */



/* Blog */

.add-blog .content-box{
    border: 1px solid grey;
    border-radius: 8px;
    /* display: flex;
    flex-direction: row; */
    margin: 20px;
    padding: 10px;
    /* justify-content: space-around; */
}
.add-blog .blog-flex-row{
    background-color: aqua;
    /* display: flex;
    flex-direction: row; */
}
.add-blog .form-group{
    margin: 20px;
}
.add-blog .form-check{
    margin: 20px;
}


/* Events */
.add-event .content-box{
    border: 1px solid grey;
    border-radius: 8px;
    /* display: flex;
    flex-direction: row; */
    margin: 20px;
    padding: 10px;
    /* justify-content: space-around; */
}
.add-event .event-flex-row{
    background-color: aqua;
    /* display: flex;
    flex-direction: row; */
}
.add-event .form-group{
    margin: 20px;
}
.add-event .form-check{
    margin: 20px;
}