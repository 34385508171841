@media (max-width: 576px) {
    .show-in-mobile {
        display: flex;
    }

    .hide-in-mobile {
        display: none;
    }
}

.bg-color-f0f0f0 {
    background-color: #F0F0F0;
    margin-top: 40px 0px;
    padding: 30px 0px;
    border-top: 1px solid #F6F6F6;
    border-bottom: 1px solid #F6F6F6;
}

.bg-color-161515 {
    background-color: #161515;
    margin-top: 40px 0px;
    padding: 30px 0px;
}

/* Testimonials  */

/* Rating Star Css */
.rating-star i {
    color: #FFF;
    margin: 5px;
}

.orange-rating-star {
    margin: 5px !important;
}

.orange-rating-star i {
    color: orange;
    margin: 3px;
}

.dark-testimonial-section {
    background: #000;
    padding: 60px 0px;
}


.dark-testimonial-section .testimonial-heading {
    margin-bottom: 40px;
}

.dark-testimonial-section .testimonial-heading h6 {
    width: 60%;
    margin: 0px auto;
    white-space: normal;
    text-overflow: initial;
    color: #FFF;
    text-align: center;
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    line-height: 62px;
    /* 129.167% */

}

.dark-testimonial-section .testimonial-card {
    /* margin: 0px 30px; */
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    height: 330px;
    /* Set a fixed height for vertical scrolling */
    overflow-y: auto;
    /* Enable vertical scrolling */
}

.testimonial-card::-webkit-scrollbar {
    width: 10px;
    /* Set the width of the scrollbar */
}

.testimonial-card::-webkit-scrollbar-thumb {
    background-color: #00AEEF;
    /* Set the color of the scrollbar thumb */
    border-radius: 4px;
    /* Optional: Add some border-radius for a rounded appearance */
}

.dark-testimonial-section .testimonial-card>div {
    flex: 0 0 calc(33.3333% - 20px);
    margin: 10px;
    padding: 20px;
    border-radius: 16px;
    background: var(--primary-blue, #00AEEF);
    box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;
    width: 420px;
    height: 320px;
    position: relative;
}

.dark-testimonial-section .testimonial-card p {
    margin-top: 20px;
    color: #FFF;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 137.5% */
}

.dark-testimonial-section .testimonial-card .profile {
    /* margin-top: 30px; */
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 0;
}

.dark-testimonial-section .testimonial-card .profile div {
    margin: 20px;
}

.dark-testimonial-section .testimonial-card .profile div h4 {
    color: #FFF;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    /* 122.222% */
}

.dark-testimonial-section .testimonial-card .profile div h5 {
    color: #070707;
    font-size: 0.825rem;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 157.143% */
}

@media (max-width: 576px) {
    .dark-testimonial-section .testimonial-heading h6 {
        width: 90%;
    }

    .dark-testimonial-section{
        max-width: 90%;
    }
    
    .dark-testimonial-section .testimonial-card>div {
        flex: 0 0 calc(100% - 20px);
        padding: 20px;
        height: 310px;
    }

}
/* Banner Logo  */
.banner-container{
    max-width: 100%;
    .slick-arrow{
        display: none !important;
    }
}
.banner-image{
    width: 130px;
    >img{
        max-width: 130px;
        height: auto;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 576px) {
    .banner-container{
        max-width: 90%;
        .slick-arrow{
            display: none !important;
        }
    }
    .banner-image{

        margin: 20px 0px;
        width: 130px;
        >img{
            max-width: 130px;
            height: auto;
        }
    }

}

/* FAQ */
.faq-parent-div {
    /* margin: 50px 0px; */
    text-align: center;
}

.faq-div {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    text-align: left;
    /* align-items: center; */
}


.faq-div .col-md-8 {
    margin: 40px 40px;
}

.faq-div .col-md-8 h6 {
    margin-top: 2rem;
    color: #000;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.faq-div .col-md-8 p {
    color: #45494E;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.faq-div .col-md-8 .contact-us {
    color: #181818;
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.24px;
    border-radius: 8px;
    background: var(--primary-green, #B9E500);
    box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;
    border: none;
    width: 170px;
    height: 55px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
}


.faq-image-div {
    margin: 40px 0px;
    
    >img{
        max-width: 100%;
    }
}

#card-q-a {
    margin-bottom: 10px;
    background-color: #FFF;
    border-radius: 16px !important;
    border: none;
}

#card-q-a .card-header {
    background-color: #FFF;
    border-bottom: none;
}

#card-q-a button {
    color: var(--Text-txt-primary, #000);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
}

#card-q-a .card-body {
    color: var(--Text-txt-secondary, rgba(60, 60, 67, 0.85));
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 25.2px */
}

@media (max-width: 576px) {
    .faq-div {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        text-align: left;
        /* align-items: center; */
    }

    .faq-div .col-md-8 {
        margin: 10px 0px;
    }

    .faq-image-div {
        display: none;
        margin: 40px 0px;
    }

    #card-q-a button {
        color: var(--Text-txt-primary, #000);
        font-size: 1rem;
        text-align: left;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration: none;
    }

    #card-q-a .card-body {
        color: var(--Text-txt-secondary, rgba(60, 60, 67, 0.85));
        font-size: 0.85rem;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        /* 25.2px */
    }
}


/* Peer Learning */

.peer-learning {
    >h1 {
        color: #FFF;
        text-align: center;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    >h4 {
        color: #C7C7C7;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}

.peer-learning .learning-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.peer-learning .learning-box .learning-card {
    width: 340px;
    margin: 20px;
    min-height: 260px;
    padding: 40px;
    border-radius: 16px;
    border: 1px solid var(--primary-blue, #00AEEF);
    background: #F4F4F4;
    box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;

    >p {
        color: #343434;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.peer-learning .learning-box .learning-card .flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    >h6 {
        color: #070707;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.48px;
    }

}

@media (max-width: 576px) {
    .peer-learning .learning-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

/* Grow  */
.grow {
    padding: 50px;

    >h3 {
        color: #FFF;
        font-size: 4rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    >h4 {
        color: #FFF;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 20px;
    }
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.grow .border-design {
    border-radius: 16px;
    border: 1px solid #3F3C4D;
    background: rgba(24, 24, 24, 0.30);
    padding: 30px;
    margin: 10px;
}

.grow .first-column {
    width: 6000px;
}

.grow .grow-content h6 {

    color: #FFF;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 10px;

}

.grow .grow-content img {
    float: right;
    position: relative;
}

.grow .grow-content p {
    color: #FFF;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
}

.grow-bottom-box {
    display: flex;
    flex-direction: row;
    padding: 20px;
}

.grow-bottom-box div {

    >h4 {
        color: #FFF;
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    >h5 {
        margin-top: 30px;
        color: #FFF;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 250;
        line-height: normal;
    }
}

.grow-bottom-div {
    max-width: calc(80%-20px);
}


.grow-bottom-card-white {
    border-radius: 14px;
    background: var(--White, #F7F7F7);
    max-width: 260px;
    min-height: 350px;
    padding: 20px;
    margin: 10px;

    >h6 {
        color: #000;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    >p {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.grow-bottom-card-green {
    border-radius: 14px;
    background: var(--primary-green, #B9E500);
    max-width: 260px;
    min-height: 350px;
    padding: 20px;
    margin: 10px;

    >h6 {
        color: #000;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    >p {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.slick-dots li button:before {
    top: -60px;
    left: -640px;
    color: white;
    font-size: 1.25rem;
    line-height: 20px;
}   

.slick-dots li button:focus {
    color: white;
}


@media (max-width: 576px) {
    .grow {
        padding: 10px;
    }

    .grow .flex-row {
        display: flex;
        flex-direction: column !important;
    }

    .grow .first-column {
        width: 100%;
    }

    .grow-bottom-box {
        display: flex;
        flex-direction: column;
    }

    .grow-bottom-div {
        width: 100%;
    }

    .slick-dots li button:before {
        /* top: -60px;
        left: -770px; */
        color: var(--primary-green, white);

    }

    .grow-bottom-card-white {
        border-radius: 14px;
        background: var(--White, #F7F7F7);
        max-width: calc(100% -10px);

        /* min-height: 350px;
        padding: 20px;
        margin: 10px; */

        >h6 {
            color: #000;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        >p {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    .grow-bottom-card-green {
        border-radius: 14px;
        background: var(--primary-green, #B9E500);
        max-width: calc(100% -10px);
        /* min-height: 350px;
        padding: 20px;
        margin: 10px; */

        >h6 {
            color: #000;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        >p {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

}


/* Unique */

.unique {
    padding: 50px 0px;

    >h6 {
        text-align: center;
        color: #FFF;
        text-align: center;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}

.unique-content-area {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .images {
        margin-top: 90px;
    }
}

.unique-content {
    padding: 20px;
    margin: 30px;
    border-radius: 16px;
    border: 1px solid #B9E500;
    background: linear-gradient(0deg, rgba(24, 24, 24, 0.70) 0%, rgba(24, 24, 24, 0.70) 100%), url(<path-to-image>), lightgray 50% / cover no-repeat;

    >p {
        color: #DADADA;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-top: 2px;
    }
}

.unique-content-area .second-box {
    margin-top: 80px;

}

.unique-content .content-header-white {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    padding: 20px;
    margin-bottom: 10px;

    border-radius: 16px;
    background: #FFF;
}

.unique-content .content-header-blue {
    color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    padding: 20px;
    margin-bottom: 10px;

    border-radius: 16px;
    background: var(--primary-blue, #00AEEF);
}

@media (max-width: 576px) {
    .unique-content-area {
        display: flex;
        flex-direction: column !important;
    }

    .unique-content-area .second-box {
        margin-top: 0px;

    }

    .unique-content {
        padding: 20px;
        margin: 30px;
        border-radius: 16px;
        border: 1px solid #B9E500;
        background: linear-gradient(0deg, rgba(24, 24, 24, 0.70) 0%, rgba(24, 24, 24, 0.70) 100%), url(<path-to-image>), lightgray 50% / cover no-repeat;

        >p {
            color: #DADADA;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding-top: 2px;
        }
    }

    .unique-content .content-header-white {
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.48px;
        padding: 20px;
        margin-bottom: 10px;

        border-radius: 16px;
        background: #FFF;
    }

    .unique-content .content-header-blue {
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.48px;
        padding: 20px;
        margin-bottom: 10px;

        border-radius: 16px;
        background: var(--primary-blue, #00AEEF);
    }
}


/* Pathways */


.pathways {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pathways .course-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #000;
}

.pathways .course-box .heading-box {
    min-width: 250px;
    height: 150px;
    border: 1px solid #ccc;
    padding: 20px;
    margin: 10px;
    position: relative;
    margin: 20px;

    border-radius: 16px;
    border: 2px solid var(--primary-green, #B9E500);
    background: var(--secondary-green, #D8FFAC);
    box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;

    >h6 {
        color: #000;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}

/* div {
    border: 1px solid #ccc;
    padding: 10px;
    margin: 10px;
    position: relative;
  } */

.tab-button {
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 50%;
    width: 60px;
    height: 60px;
}

.tab-button span {
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;
    display: block;
    background-color: #ccc;
    border: 1px solid #ccc;
}

.tab-button.active span {
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;
    display: block;
    background-color: #B9E500;
    border: 1px solid #B9E500;
}

.pathways .tab-content.content-area  {
    display: none;
    padding: 20px;

}

.pathways .tab-content.content-area.active {
    display: flex;
}

.pathways .content-area {
    padding: 30px;
    background-color: #000;
    flex-direction: column;
    align-items: center;

    .view-more {
        padding: 12px 24px;
        margin: 20px 0px;
        max-width: 300px;
        border-radius: 8px;
        text-decoration: none;
        border: 1px solid var(--primary-blue, #00AEEF);
        background: #FFF;
        color: #181818;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.32px;
    }
}

.pathways .content-area .content-box {
    display: flex;
    flex-direction: row;
}

.pathways .content-area .content-box .content-card {
    border-radius: 16px;
    border: 1px solid #3F3C4D;
    background: rgba(69, 69, 69, 0.10);
    padding: 30px 20px;
    margin: 10px;
    width: calc(33%);

    >h6 {
        color: var(--primary-blue, #00AEEF);
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        /* 110% */
    }

    >p {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.pathways .content-area .content-box .content-card:hover {
    border-radius: 16px;
    border: 1px solid #3F3C4D;
    background: var(--primary-blue, #00AEEF);
    backdrop-filter: blur(17px);
    display: flex;
    flex-direction: column-reverse;

    >h6 {
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
    }
}

@media (max-width: 576px) {
    .pathways .course-box {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: #000;
    }

    .pathways .course-box .heading-box {
        min-width: 300px;
        height: 100px;
        text-align: center;

        >h6 {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }

    .pathways .content-area {
        padding: 10px;
        background-color: #000;

    }

    .pathways .content-area .content-box {
        display: flex;
        flex-direction: column;
    }

    .pathways .content-area .content-box .content-card {
        border-radius: 16px;
        border: 1px solid #3F3C4D;
        background: rgba(69, 69, 69, 0.10);
        padding: 30px 20px;
        margin: 10px 0px;
        width: calc(100%);

        >h6 {
            color: var(--primary-blue, #00AEEF);
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
            /* 110% */
        }

        >p {
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}