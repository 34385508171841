.login-modal{
    /* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background for the blur effect */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Modal Content */
/* .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    position: relative;
  }
   */
/* Close Button */
.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
}


.modal-content {
    display: flex;
    width: 520px;
    padding: 110px 60px 110px 60px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;
    position: relative;
    background: linear-gradient(325deg, #00AEEF 0.74%, #B9E500 100%);
}

.modal-content {
    /* >h2 {
        color: #FFF;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .form-label{
        color: #000;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
    } */
}

.modal-overlay-admin {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 1);
    /* Semi-transparent background for the blur effect */
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content-admin {
    display: flex;
    width: 480px;
    padding: 110px 60px 110px 60px;
    flex-direction: column;
    justify-content: center;
    border-radius: 16px;
    align-items: center;
    gap: 48px;
    position: relative;
    background: rgb(43, 43, 43);
    .form-label{
        color: #FFF !important;
    }
}
}