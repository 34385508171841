.hide-div {
  height: 0;
  opacity: 0;
  width: 0;
  overflow: hidden;
  transition: width 1s ease-in-out, opacity 1s ease-in;
}

.show-div {
  opacity: 1;
  transition: width 1s ease-in-out, opacity 1s ease-in;
}

/* First Section  */
.first-section-container {
  padding-top: 90px;
}

.first-section-container .first-card-box h1 {
  margin-top: 100px;
  color: #FFF;
  /* font-family: Raleway; */
  font-size: 4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 84px;

  /* 131.25% */
  width: 70%;
  white-space: normal;
  text-overflow: initial;
}

.first-section-container .first-card-box h4 {
  margin-top: 25px;
  color: #FFF;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 70%;
  white-space: normal;
  text-overflow: initial;
}

.first-section-container .first-card-box button {
  border-radius: 8px;
  border: 2px solid var(--primary-blue, #00AEEF);
  /* border: none; */
  background: #000;
  padding: 10px 30px;
  /* margin-top: 40px; */
  color: #FFF;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.32px;
}

.first-section-container .circle-text {
  color: #BFBFBF;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
}











.first-section-container {
  /* min-height: 30rem; */
}

.first-section-container .container-box {
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  padding: 0px;
}

.first-section-container .container-box .my-box.show-content {
  opacity: 1;
  transition: opacity 1s ease-in;
}

.first-section-container .container-box .hide-content {
  width: 0;
  height: 0;
  opacity: 0;
}

.first-section-container .container-box .my-box {
  transition: width 1s ease-in-out, opacity 1s ease-in;
}

.first-section-container .container-box .title-box .show-content-h3 {
  transition: transform 1.5s;
  transform: rotate(180deg);
}

.first-section-container .container-box .title-box .hide-content-h3 {
  transition: transform 1s;
}

.first-section-container .container-box .slide-item {
  width: 80px;
}

.first-section-container .container-box .slide-item.shift-left {
  width: 100%;
  opacity: 1;
  transition: width 1s ease-in-out, opacity 1s ease-in;
}

.first-section-container .container-box .slide-item {
  transition: width 1s ease-in-out, opacity 1s ease-in;
}

.first-section-container .content-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.first-section-container .container-box .title-box {
  min-width: 70px;
  min-height: 45rem;
  display: flex;
  padding-top: 30px;
  flex-direction: column;

  /* justify-content: center; */
  /* align-items: center; */
  >button {
    border: none;
    background-color: transparent;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
  }

  >h3 {
    margin-top: 15px;
    writing-mode: vertical-rl;
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.first-section-container .my-box {
  flex: 1;
  max-width: 100%;
  overflow: hidden;
}


.first-section-container .container-box .shift-left {
  margin-left: 0%;
}

/* .content-container {
  display: flex;
}

.content {
  width: 100%;
  text-align: center;
  padding: 20px;
} */


/* First section how it works content  */

.first-section-container .content-box {
  align-items: normal;

  >h2 {
    margin: 30px 0px 0px;

    >.span-1 {
      color: var(--primary-blue, #00AEEF);
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    >.span-2 {
      color: var(--primary-green, #B9E500);
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    >.span-3 {
      color: #FFF;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  >img {
    margin: 40px 0px;
  }
}

.first-section-container .content-box .second-row-container {
  margin: 40px 0px;

  >.card-area {
    >.card-box {
      border-radius: 16px;
      border: 1px solid #B9E500;
      background: rgba(24, 24, 24, 0.70);
      padding: 25px;
      margin: 10px;
      width: calc(33.3333%);

      >h3 {
        color: #FFF;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 10px;
      }

      >p {
        color: #ABABAB;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 10px;
      }
    }
  }

  >h2 {
    color: #FFF;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: right;
  }

  >p {
    color: #FFF;
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}


.first-section-container .content-box-2 {
  background-color: #1A1A1A;
  padding: 40px 0px;

  >h2 {
    padding: 10px;
    color: #FFF;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  >p {
    padding: 30px 10px;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  >.first-row-container {
    >.card-area {
      >.card-box {
        padding: 20px;
        margin: 10px;
        border-radius: 16px;
        border: 1px solid #00AEEF;
        box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;

        >h3 {
          color: #FFF;
          /* text-align: center; */
          font-size: 40px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        >p {
          padding-top: 15px;
          color: #FFF;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        >a {
          >button {
          padding: 12px 24px;
          border-radius: 8px;
          border: 1px solid #B9E500;
          background: #FFF;

          color: #181818;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.32px;
        }
      }
      }

      >.card-box:hover {
        border-radius: 16px;
        border: 1px solid #B9E500;
        background: var(--secondary-green, #D8FFAC);
        box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;

        >h3 {
          color: #000;
          /* text-align: center; */
          font-size: 40px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        >p {
          padding-top: 15px;
          color: #000;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        >button {
          border-radius: 8px;
          border: 1px solid #B9E500;
          background: #FFF;
          padding: 12px 24px;

          color: #181818;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.32px;
        }
      }
    }
  }

  >.second-row-container {
    padding: 40px 10px;

    >h3 {
      margin: 30px 0px;
      color: #FFF;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 62px;
    }

    >p {
      color: #FFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 30px 0px;
    }

    >.second-card-box {
      border-radius: 8px;
      background: #000;
      padding: 20px 40px;
      margin: 10px 0px;

      >h6 {
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.36px;
      }

      >p {
        color: #B3B3B3;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

  }
}



/* Third section kry high lights  */

.flex-row {
  display: flex;
  flex-direction: row;
}

.content-box-3 {
  padding: 40px 10px;
  >h2 {
    padding: 0px 10px 30px;
    color: #FFF;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.content-box-3 .first-row-container p {
  margin: 30px 10px 40px;
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.content-box-3 .first-row-container .first-row .card-box-first {
  padding: 10px;
  max-width: 230px;
  max-height: 230px;
  min-height: 230px;
  margin: 10px;
  border-radius: 16px;
  border: 1px solid var(--primary-blue, #00AEEF);
  /* background: url(<path-to-image>), lightgray 50% / cover no-repeat, #181818; */

  >h3 {
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  >p {
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  >img {
    float: right;
  }
}


.content-box-3 .first-row-container .first-row .card-box-first.large {
  padding: 20px;
  min-width: 460px;
  min-height: 470px;
  margin: 10px;
  border-radius: 16px;
  border: 1px solid var(--primary-blue, #00AEEF);
  /* background: url(<path-to-image>), lightgray 50% / cover no-repeat, #181818; */

  >h3 {
    color: #FFF;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  >p {

    color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  >img {
    float: right;
  }
}

.content-box-3 .first-row-container .first-row .flex-row-wrap {
  display: flex;
  flex-wrap: wrap;
}

/* .card-box-first {
  width: 25%;
  height: 25%;
  transition: width 0.3s, height 0.3s;
} */
/*
.flex-row.large .card-box-first {
  width: 50%;
  height: 50%;
} */

/* .large-card {
  width: 50% !important;
  height: 50% !important;
}

.card-box-first {
  width: 20%;
  height: 20%;
} */




@media (max-width: 576px) {
  .first-section-container-mobile{
    padding-top: 10px;
  }
  .first-section-container-mobile .content-box {
    align-items: normal;
  
    >h2 {
      margin: 20px 0px 0px;
      >.span-1 {
        color: var(--primary-blue, #00AEEF);
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
  
      >.span-2 {
        color: var(--primary-green, #B9E500);
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
  
      >.span-3 {
        color: #FFF;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  
    >img {
      margin: 20px 0px 40px;
    }
  }

  .second-row-container-mobile {
    margin: 40px 0px;
  
    >.card-area {
      display: flex;
      flex-direction: column !important;
      >.card-box {
        border-radius: 16px;
        border: 1px solid #B9E500;
        background: rgba(24, 24, 24, 0.70);
        margin: 10px 0px;
  
        >h3 {
          color: #FFF;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin: 10px;
        }
  
        >p {
          color: #ABABAB;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin: 10px;
        }
      }
    }
  
    >h2 {
      color: #FFF;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-align: right;
    }
  
    >p {
      color: #FFF;
      text-align: right;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  
}

.first-row-container .fourth-about-container .about-cards .colorful-card{
  padding: 10px !important;
  >h3{
    font-size: 1.75rem;
  }
}

@media (max-width: 576px) {
  .first-section-container-mobile .content-box-2 {
    background-color: #1A1A1A;
    padding: 40px 0px;
  
    >h2 {
      padding: 10px;
      color: #FFF;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  
    >p {
      padding: 30px 10px;
      color: #FFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  
    >.first-row-container {
      >.flex-row {
        flex-direction: column !important;
      }
      >.card-area {
        >.card-box {
          padding: 20px;
          margin: 10px;
          border-radius: 16px;
          border: 1px solid #00AEEF;
          box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;
  
          >h3 {
            color: #FFF;
            /* text-align: center; */
            font-size: 40px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
  
          >p {
            padding-top: 15px;
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
  
          >a {
            >button {
            padding: 12px 24px;
            border-radius: 8px;
            border: 1px solid #B9E500;
            background: #FFF;
  
            color: #181818;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.32px;
          }
        }
        }
  
        >.card-box:hover {
          border-radius: 16px;
          border: 1px solid #B9E500;
          background: var(--secondary-green, #D8FFAC);
          box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;
  
          >h3 {
            color: #000;
            /* text-align: center; */
            font-size: 40px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
  
          >p {
            padding-top: 15px;
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
  
          >button {
            border-radius: 8px;
            border: 1px solid #B9E500;
            background: #FFF;
            padding: 12px 24px;
  
            color: #181818;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.32px;
          }
        }
      }
    }
  
    >.second-row-container {
      padding: 40px 10px;
  
      >h3 {
        margin: 30px 0px;
        color: #FFF;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 62px;
      }
  
      >p {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 30px 0px;
      }
  
      >.second-card-box {
        border-radius: 8px;
        background: #000;
        padding: 20px 40px;
        margin: 10px 0px;
  
        >h6 {
          color: #FFF;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.36px;
        }
  
        >p {
          color: #B3B3B3;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
  
    }
  }
}


.explore-courses{
  background-color: #000;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  >a{
    padding: 5px 10px;
    text-decoration: none;
    color: #fff;
    width: 100%;
    border-bottom: 0.1px solid rgb(26, 26, 26);
  }

}
.explore-div{
  /* border-radius: 8px; */
  /* border: 2px solid var(--primary-blue, #00AEEF); */
  background: #000;
  margin-top: 40px;
  max-width: fit-content;

}