.first-blogs-section .blog-area.hide-blog{
    display: none;
}
.first-blogs-section .blog-area {
    margin: 40px 0px;
    .blogs-flex-row{
        display: flex;
        flex-direction: row;
    }
    .content-image{
        max-width: 60%;
        >img{
            border-radius: 16px;
            max-width: 100%;
        }
    }
    .content {
        width: 40%;
        >h6 {
            color: #FFF;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            border-radius: 8px;
            border: 0px solid var(--primary-green, #B9E500);
            background: var(--primary-green, #B9E500);
            width: fit-content;
            padding: 5px 10px;
        }

        >h5 {
            color: #181818;
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        >h4 {
            color: #181818;
            font-size: 36px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        >p {
            margin: 20px 0px;
            color: #707070;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 136%;
            /* 19.04px */
        }

        .read-now {
            font-size: 1rem;
            border-radius: 8px;
            border: 1px solid var(--primary-green, #B9E500);
            background: #FFF;
            color: #181818;
            text-align: center;
            padding: 14px 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.32px;
        }
    }

    .slide-button {
        display: flex;
        flex-direction: row;
        justify-content: right;
        align-items: center;
        margin: 20px;

        >button {
            margin: 5px;
            border-radius: 8px;
            padding: 5px 10px;
            border: 2px solid var(--primary-green, #B9E500);
            background: rgba(0, 0, 0, 0.00);
        }

        >div {
            margin: 5px;
            height: 5px;
            width: 5px;
            padding: 5px;
            border-radius: 50%;
            background-color: #707070;
        }

        >div.selected {
            background-color: var(--primary-green, #B9E500);
        }
    }

}

@media (max-width: 576px) {

    .first-blogs-section .blog-area {
        margin: 40px 0px;
        .blogs-flex-row{
            display: flex;
            flex-direction: column-reverse;
        }
        
        .content {
            width: 100%;
            margin: 10px 0px;
            >h6 {
                margin-top: 10px;
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                border-radius: 8px;
                border: 0px solid var(--primary-green, #B9E500);
                background: var(--primary-green, #B9E500);
                width: fit-content;
                padding: 5px 10px;
            }
    
            >h5 {
                color: #181818;
                font-size: 36px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
    
            >h4 {
                color: #181818;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
    
            >p {
                margin: 20px 0px;
                color: #707070;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 136%;
                /* 19.04px */
            }
    
            .read-now {
                font-size: 1rem;
                border-radius: 8px;
                border: 1px solid var(--primary-green, #B9E500);
                background: #FFF;
                color: #181818;
                text-align: center;
                padding: 14px 24px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 0.32px;
            }
        }
        .content-image{
            max-width: 100%;
            >img{
                width: 100%;
            }
        }
    
        .slide-button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin: 20px;
    
            >button {
                margin: 5px;
                border-radius: 8px;
                padding: 5px 10px;
                border: 2px solid var(--primary-green, #B9E500);
                background: rgba(0, 0, 0, 0.00);
            }
    
            >div {
                margin: 5px;
                height: 5px;
                width: 5px;
                padding: 5px;
                border-radius: 50%;
                background-color: #707070;
            }
    
            >div.selected {
                background-color: var(--primary-green, #B9E500);
            }
        }
    
    }

}



.events-section {
    padding: 40px 0px;
}

.events-section .events-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.events-section .container {
    >h2 {
        padding: 10px;
        color: #181818;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 62px;
    }
}

.events-section .event {
    border: 1px solid #ccc;
    border-radius: 16px;
    /* padding: 16px; */
    /* margin: 16px; */
    width: calc(33.33% - 32px);
    /* Adjust the width based on your layout */
    box-sizing: border-box;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;

}

.events-section .event:hover {
    transform: scale(1.05);
}

.events-section .event {
    margin: 30px 0px;

    .event-content {
        padding: 10px;
        margin-top: 12px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        >h3 {
            margin-bottom: 8px;
            color: #181818;
            font-size: 28px;
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: 0.56px;
        }

        >button {
            border-radius: 8px;
            border: 1px solid #B9E500;
            background: #FFF;
            color: #181818;
            text-align: center;
            padding: 14px 24px;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.32px;
            cursor: pointer;
        }
    }

    >p {
        padding: 10px;
        color: #707070;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }

}
.events-section .event .event-top{
    position: relative;
    .event-image{
        border-radius: 16px 16px 0px 0px;
        object-fit: cover;
        width: calc(100%);
    }
}
.event-datetime{
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    padding: 2px 4px;
    top: 0.5rem;
    right: 0.5rem;
    color: #000;
    font-weight: bold;
    border-radius: 8px;
}
.events-container .show-more-events {
    border-radius: 8px;
    border: 1px solid var(--primary-blue, #00AEEF);
    background: #FFF;
    color: #181818;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;
    padding: 14px 24px;
}

@media (max-width: 576px) {
    .events-section .events-container {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
    }
    .events-section .container {
        >h2 {
            padding: 10px;
            color: #181818;
            font-size: 36px !important;
            font-style: normal;
            font-weight: 600;
            line-height: 62px;
        }
    }
    .events-section .event {
        margin: 30px 0px;
        border: 1px solid #ccc;
        border-radius: 16px;
        width: calc(100% - 20px);
        box-sizing: border-box;
        overflow: hidden;
        transition: transform 0.3s ease-in-out;
    
        .event-content {
            padding: 10px;
            margin-top: 12px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
    
            >h3 {
                margin-bottom: 8px;
                color: #181818;
                font-size: 28px;
                font-style: normal;
                font-weight: 600;
                line-height: 32px;
                letter-spacing: 0.56px;
            }
    
            >button {
                border-radius: 8px;
                border: 1px solid #B9E500;
                background: #FFF;
                color: #181818;
                text-align: center;
                padding: 14px 24px;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 0.32px;
                cursor: pointer;
            }
        }
    
        >p {
            padding: 10px;
            color: #707070;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
        }
    
    }
    .event-datetime{
        background-color: rgba(255, 255, 255, 0.5);
        position: absolute;
        padding: 2px 4px;
        top: 0.5rem;
        right: 0.5rem;
        color: #000;
        font-weight: bold;
        border-radius: 8px;
    }
}

/* Add more styles as needed */


.blogs-section {
    padding: 40px 0px;
}

.blogs-section .container {
    /* max-width: 1200px; */
    margin: 0 auto;

    >h2 {
        padding: 10px 20px;
        color: #000;
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}

.blogs-section .blogs-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.blogs-section .blog {
    padding: 10px;
    width: 23%;
    /* Adjust as needed to fit 4 events in a row */
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;

    >img {
        width: 100%;
        height: auto;
        border-bottom: 1px solid #ddd;
        /* Optional border between image and content */
    }


    >h6 {
        padding-top: 10px;
        color: var(--primary-green, #B9E500);
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 130.5%;
        /* 13.05px */
        text-transform: uppercase;
    }

    >h2 {
        color: #191919;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 136%;
        /* 21.76px */
    }


    >button {
        color: #181818;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 136%;
        /* 16.32px */
        border: none;
        background-color: #FFF;
    }
}

.blogs-section .blog:hover {
    transform: scale(1.05);
}

.blogs-section .show-more-blogs {
    padding: 14px 24px;
    color: #181818;
    max-width: fit-content;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;
    border-radius: 8px;
    border: 1px solid var(--primary-blue, #00AEEF);
    background: #FFF;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

@media (max-width: 576px) {

    .blogs-section .blogs-container {
        display: flex;
        flex-direction: column !important;
        justify-content: center;
        align-items: center;
    }
    
    .blogs-section .blog {
        padding: 10px;
        width: calc(100% - 20px);
        margin-bottom: 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        overflow: hidden;
        transition: transform 0.3s ease-in-out;
    
        >img {
            width: 100%;
            height: auto;
            border-bottom: 1px solid #ddd;
        }
    
        >h6 {
            padding-top: 10px;
            color: var(--primary-green, #B9E500);
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 130.5%;
            text-transform: uppercase;
        }
    
        >h2 {
            color: #191919;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 136%;
        }
    
        >button {
            color: #181818;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 136%;
            border: none;
            background-color: #FFF;
        }
    }

}

/* Add more styles as needed */



.popular-blogs-section {
    padding: 40px 0px;
}

.popular-blogs-section .container {
    /* max-width: 1200px; */
    margin: 0 auto;

    >h2 {
        padding: 10px 20px;
        color: #000;
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}

.popular-blogs-section .blogs-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.popular-blogs-section .blog {
    padding: 10px;
    width: 23%;
    /* Adjust as needed to fit 4 events in a row */
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;

    >img {
        width: 100%;
        height: auto;
        border-bottom: 1px solid #ddd;
        /* Optional border between image and content */
    }

    >h6 {
        padding-top: 10px;
        color: var(--primary-green, #B9E500);
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 130.5%;
        /* 13.05px */
        text-transform: uppercase;
    }

    >h2 {
        color: #191919;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 136%;
        /* 21.76px */
    }

    >button {
        color: #181818 !important;
        text-decoration: none;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 136%;
        /* 16.32px */
        border: none;
        background-color: #FFF;
    }
}

.read-more {
    color: #181818 !important;
    text-decoration: none;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 136%;
    /* 16.32px */
    border: none;
    background-color: #FFF;
}

.popular-blogs-section .blog:hover {
    transform: scale(1.05);
}

.popular-blogs-section .show-more-blogs {
    padding: 14px 24px;
    color: #181818;
    max-width: fit-content;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;
    border-radius: 8px;
    border: 1px solid var(--primary-blue, #00AEEF);
    background: #FFF;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

@media (max-width: 576px) {

    .popular-blogs-section .blogs-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .popular-blogs-section .blog {
        padding: 10px;
        width: calc(100% - 20px);
        /* Adjust as needed to fit 4 events in a row */
        margin-bottom: 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        overflow: hidden;
        transition: transform 0.3s ease-in-out;
    
        >img {
            width: 100%;
            height: auto;
            border-bottom: 1px solid #ddd;
            /* Optional border between image and content */
        }
    
        >h6 {
            padding-top: 10px;
            color: var(--primary-green, #B9E500);
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 130.5%;
            /* 13.05px */
            text-transform: uppercase;
        }
    
        >h2 {
            color: #191919;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 136%;
            /* 21.76px */
        }
    
        >button {
            color: #181818 !important;
            text-decoration: none;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 136%;
            /* 16.32px */
            border: none;
            background-color: #FFF;
        }
    }
}
/* Blog  */
.blog-page-section {
    padding: 40px 0px;
}

.blog-page-section .blog {
    width: 100%;

    >h6 {
        color: var(--primary-blue, #00AEEF);
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 156%;
        /* 24.96px */
        text-transform: uppercase;
    }

    >h2 {
        color: #000;
        font-size: 3rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    >img {
        margin: 10px 0px;
        border-radius: 8px;
        /* background: url(<path-to-image>), lightgray 0px -71.792px / 100% 123.251% no-repeat; */
        max-width: 100%;
    }

    >div {
        padding: 40px 0px;

        >img {
            width: 60px;
            height: 60px;
            flex-shrink: 0;
        }

        >h4 {
            color: #707070;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 136%;
            margin-left: 10px;
            /* 21.76px */
            text-transform: uppercase;
        }

        >h5 {
            color: #707070;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 136%;
            text-transform: uppercase;
        }
    }

    >h4 {
        padding: 15px 0px;
        color: #181818;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        /* 116.667% */
    }
}

.blog-page-section .flex-row {
    align-items: center;
}

@media (max-width: 576px) {
    .blog-page-section .blog {
        width: 100%;
    
        >h6 {
            color: var(--primary-blue, #00AEEF);
            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            line-height: 156%;
            /* 24.96px */
            text-transform: uppercase;
        }
    
        >h2 {
            color: #000;
            font-size: 2rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

    }
}