.hide-in-mobile {
    @media (max-width: 576px) {
        display: none;
    }
}

/* First section  */
.first-section {
    margin: 0px;
    position: relative;

}

.vertical-box {
    position: absolute;
    top: 50px;
    right: 100px;
    /* bottom: -1rem; */
    bottom: 0;
    width: 400px;
    border-radius: 30px 30px 0px 0px;
    background: var(--Background-accent-off, #F5F5F5);
    box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;
}

.vertical-box .image-area img {
    width: 360px;
    max-height: 280px;
    margin: 20px;
    border-radius: 30px 30px 0px 0px;
    background: url(<path-to-image>), lightgray 50% / cover no-repeat, #D9D9D9;
    box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;
}

.vertical-box .content-area {
    margin: 30px 20px;
}
.h6-text {
    margin-top: 10px;
}
.vertical-box .content-area {
    /* >img {
        width: 40px;
        height: 40px;
    } */
    .h6-text {
        margin-top: 10px;
        color: #000;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .p-text {
        color: #6F6F6F;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.first-section .first-area {
    background-color: #000;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.first-section .first-area .left-container {
    margin-left: 50px;

    >h6 {
        color: #FFF;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    >h2 {
        color: #FFF;
        font-size: 4rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    >p {
        color: #FFF;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        max-width: 90%;
    }

    >button {
        margin-top: 30px;
        border-radius: 8px;
        background: var(--primary-green, #B9E500);
        box-shadow: -3px -3px 5px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 5px 0px rgba(0, 0, 0, 0.25) inset;
        height: 48px;
        padding: 14px 24px;

        color: #181818;
        text-align: center;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.32px;
        border: none;
    }
}

.first-section .first-area .left-container .details-box {
    display: flex;
    flex-direction: row;

    >.details-box-div {
        border-radius: 16px;
        border: 1px solid #3F3C4D;
        background: rgba(69, 69, 69, 0.30);
        padding: 10px 20px;
        margin-right: 20px;
        >div{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            color: #FFF;
            >h5 {
                padding-top: 5px;
                color: #FFF !important;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
            }
        
        }
        

        >h4 {
            color: #FFF;
            font-size: 1.75rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}

.first-section .second-area .second-box {
    >h3 {
        margin: 40px 0px 20px;
    }
}

.first-section .second-area{
    min-height: 43rem !important;
}

@media (max-width: 576px) {
    .first-section .first-area{
        max-height: 30rem !important;
    }
    .first-section .second-area{
        max-height: 30rem !important;
    }
    .first-section .first-area .left-container {
        margin-left: 10px !important;

        >h2 {
            font-size: 1.75rem !important;
        }

        >p {
            font-size: 0.5rem !important;
        }
    }

    .first-section .first-area .left-container .details-box {
        >.details-box-div {
            >h5 {
                font-size: 0.6rem !important;
            }

            >h4 {
                font-size: 1rem !important;
            }
        }
    }

    .first-section .second-area .second-box {
        >h3 {
            font-size: 1rem;
        }

        >p {
            font-size: 0.5rem;
        }
    }
}

/* Second Section  */

.curriculum {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* align-items: center; */
    padding: 50px 0px;
}

.curriculum .col-md-4 {
    margin: 10px 0px 0px 80px;
}

.curriculum .col-md-4 img {
    max-width: calc(100% - 50px);
    border-radius: 0px 0px 16px 16px;
    box-shadow: -5px -5px 10px 0px rgba(0, 0, 0, 0.25) inset, 5px 5px 10px 0px rgba(0, 0, 0, 0.25) inset;
}

.curriculum .col-md-8 {
    padding: 0px 40px;
    /* padding: 0px 80px 0px 40px; */
}

.curriculum .col-md-8 h3 {
    color: #000;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 30px;
    margin-left: 20px;
}

.curriculum .col-md-8 .card {
    margin: 20px;
}

.curriculum .col-md-8 .card-header button {
    text-decoration: none;
    text-align: left;
    width: 100%;
}

.curriculum .col-md-8 .card-header button span {
    float: right;
}

.curriculum .card-header button {
    color: #000;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

@media (max-width: 576px) {

    .curriculum .col-md-8 h3 {
        font-size: 1.25rem;
    }

    .curriculum .col-md-8 {
        padding: 0px 5px !important;
        text-align: center;
        min-width: 100% !important;
        /* padding: 0px 80px 0px 40px; */
    }

    .curriculum .card-header button {
        font-size: 0.75rem;
    }

    .curriculum .card-body {
        font-size: 0.65rem;
    }

    .curriculum .col-md-8 .card {
        margin: 10px;
    }

    .curriculum {
        padding: 30px 0px;
    }

    .curriculum .card-header button {
        width: 100%;
    }
}

/* Third Section  */
.program-highlights {
    margin-bottom: 50px;
}



.program-highlights h2 {
    color: #000;
    text-align: center;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.program-highlights .first-row {
    margin: 20px 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.program-highlights .second-row {
    margin: 20px 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.program-highlights .first-row .highlight-card {
    height: 250px;
}

.program-highlights .second-row .highlight-card {
    min-height: 200px;
}

.program-highlights .highlight-card {
    margin: 0px 10px;
    padding: 30px;
    border-radius: 16px;
    border: 1px solid var(--primary-blue, #00AEEF);
    background: var(--secondary-blue, #B3E0F2);
    box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;

    >h6 {
        color: #070707;
        margin-top: 10px;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.48px;
    }

    >p {
        margin-top: 10px;
        color: #343434;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

@media (max-width: 576px) {
    .program-highlights h2 {
        color: #000;
        text-align: center;
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .program-highlights .first-row {
        margin: 10px 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .program-highlights .second-row {
        margin: 10px 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .program-highlights .first-row .highlight-card {
        min-width: 100%;
        margin: 10px 0px;
        height: 250px;
    }

    .program-highlights .second-row .highlight-card {
        min-width: 100%;
        margin: 10px 0px;
        height: 250px;
    }
}


/* Fifth Section  */
@media (max-width: 576px) {
    .fifth-section .first-container {
        margin: 40px 10px;
        padding: 50px 0px;
    }

    .fifth-section .first-container .first-row {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        margin: 30px 0px;
        height: 100px;
    }

    /* .pap-ninth-section .first-container .first-row img {
        max-height: 100px;
    }
    .pap-ninth-section .first-container .second-row {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        margin: 50px 0px;
        height: 80px;
    }
    .pap-ninth-section .first-container .second-row img {
        max-height: 80px;
    } */

}

.fifth-section {
    padding: 40px 0px;
}

.fifth-section .first-container {
    /* margin: 60px 40px; */
    /* padding: 50px 0px; */
    /* border-radius: 16px;
    padding: 50px 0px;
    background: var(--secondary-blue, #B3E0F2);
    box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    >img {
        max-width: 100%;
    }
}

/* .fifth-section .tools-used {
    margin: 60px 40px;
    border-radius: 16px;
    padding: 50px 0px;
    background: var(--secondary-blue, #B3E0F2);
    box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;
} */

/* .fifth-section .tools-used h2 {
    text-align: center;
    color: #070707;
    font-size: 3rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 40px 0px;
}

.fifth-section .tools-used .first-row {

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: 30px 0px;
    height: 200px;
}

.fifth-section .tools-used .first-row img {
    height: 200px;
}

.fifth-section .tools-used .second-row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: 50px 0px;
    height: 180px;
}

.fifth-section .tools-used .second-row img {
    height: 180px;
} */



/* Sixth Section  */

.sixth-section .pricing h2 {
    color: #000;
    font-size: 3rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
}

.sixth-section .pricing-box {
    display: flex;
    flex-direction: row;

}



.sixth-section .pricing-box .self-paced {
    margin: 50px 30px;
    text-align: center;
    border-radius: 16px;
    background: var(--secondary-blue, #B3E0F2);
    box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;

    >img {
        margin: 50px 0px 20px;
    }

    >h4 {
        margin: 20px 0px;
    }

    >p {
        margin: 20px 40px 80px;
    }
}

.sixth-section .pricing-box .self-paced .benefit-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;

    >p {
        margin: 0px !important;
        padding-left: 10px;
    }
}

.sixth-section .pricing-box .self-paced .self-paced-benefit {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    padding: 0px 10px;
}

.sixth-section .pricing-box .self-paced .row-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.sixth-section .pricing-box .self-paced .bottom-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 50px 30px;
    align-items: center;

    >h5 {
        color: #000;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    >button {
        border-radius: 8px;
        border: 1px solid #00AEEF;
        background: #00AEEF;
        box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;
        color: #000;
        text-align: center;
        font-size: 0.625 rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.24px;
        border: none;
        padding: 20px 32px;
    }
}



.sixth-section .pricing-box .mentor-led {
    margin: 50px 30px;
    text-align: center;
    border-radius: 16px;
    background: var(--secondary-green, #D8FFAC);
    box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;

    >img {
        margin: 50px 0px 20px;
    }

    >h4 {
        margin: 20px 0px;
    }

    >p {
        margin: 20px 40px 80px;
    }
}

.sixth-section .pricing-box .mentor-led .benefit-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;

    >p {
        margin: 0px !important;
        padding-left: 10px;
    }
}

.sixth-section .pricing-box .mentor-led .mentor-led-benefit {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    padding: 0px 10px;
}

.sixth-section .pricing-box .mentor-led .row-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}


.sixth-section .pricing-box .mentor-led .bottom-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 50px 30px;
    align-items: center;

    >h5 {
        color: #000;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    >button {
        border-radius: 8px;
        border: 1px solid var(--primary-green, #B9E500);
        background: #B9E500;
        box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;
        color: #000;
        text-align: center;
        font-size: 0.625 rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.24px;
        border: none;
        padding: 20px 32px;
    }
}

@media (max-width: 576px) {
    .sixth-section .pricing-box {
        display: flex;
        flex-direction: column;

    }

    .sixth-section .pricing-box .self-paced {
        margin: 10px;
    }

    .sixth-section .pricing-box .mentor-led {
        margin: 10px;
    }

    .sixth-section .pricing-box .self-paced .bottom-box {
        padding: 10px 20px;
    }

    .sixth-section .pricing-box .mentor-led .bottom-box {
        padding: 10px 20px;
    }
}




.ceritification {
    background-color: #000;
}

.ceritification img {
    max-width: 100%;
}
.project-box{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.project-box img {
    max-width: 100%;
}