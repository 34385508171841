.first-corporate-section .container {
    height: 45rem;
    /* margin-bottom: 100px; */

    >.flex-row {
        align-items: center;
        justify-content: space-around;
    }
}

.first-corporate-section .container .content-section {
    >h1 {
        max-width: 550px;
        color: #FFF;
        font-size: 64px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}


.first-corporate-section .container .image-section {
    height: 45rem;
    >img {
        max-height: 80%;
    }
}



.second-corporate-section .container {
    padding: 40px 0px;

    >h3 {
        color: #181818;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 62px;
        /* 129.167% */
    }

    >h6 {
        padding: 20px 0px 40px;
        color: #181818;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.second-corporate-section .container .content-area {
    display: flex;
    flex-direction: row;
}
.second-corporate-section .container .content-area{
    max-width: 100%;
    >img{
        max-width: calc(50% - 20px);
        margin: 0px 10px;
    }
}
.second-corporate-section .container .content-area .content-box {
    /* max-width: 100%;
    display: flex;

    flex-direction: row;
    flex: 1; */
    width: 100%;
    >img{
        max-width: 100%;
    }
    >h4 {
        padding: 10px;
        color: #181818;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 62px;
        /* 129.167% */
    }

    >div {
        padding: 20px 10px;
        display: flex;
        flex-direction: row;
        >img{
            height: 3rem;
            width: 3rem;
        }

        /* justify-content: space-around; */
        >div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 10px;

            >h6 {
                color: #181818;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 28px;
                /* 116.667% */
            }

            >p {
                color: #565656;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }

}

@media (max-width: 576px) {

    .first-corporate-section .container .content-section {
        margin-top: 200px;
        margin-left: auto;
        margin-right: auto;

        >h1 {
            max-width: 550px;
            color: #FFF;
            font-size: 3rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }

    .second-corporate-section .container {
        padding: 40px 0px;

        >h3 {
            color: #181818;
            font-size: 48px;
            font-style: normal;
            font-weight: 600;
            line-height: 62px;
            /* 129.167% */
            text-align: center;
        }

        >h6 {
            padding: 20px 20px 40px;
            color: #181818;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    .second-corporate-section .container .content-area{
        max-width: 100%;
        >img{
            max-width: calc(100% - 20px);
            margin: 0px 10px;
        }
    }

}
.third-corporate-section h2 {
    color: #181818;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 62px;
    /* 129.167% */
}
.third-corporate-section {
    background: var(--secondary-blue, #B3E0F2);

    .container {
        padding: 50px 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .form-box {
        padding-top: 50px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    >h2 {
        color: #181818;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 62px;
        /* 129.167% */
    }

    .form-group {
        min-width: 50%;
        padding: 0px 30px;
    }

    .form-label {
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        /* 320% */
        margin-top: 40px;
    }

    .apply-button {
        margin: 50px 40px 40px;
        border-radius: 10px;
        background: var(--primary-blue, #00AEEF);
        padding: 10px 60px;
        color: #FFF;
        text-align: center;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        border: none;
    }
}

@media (max-width: 576px) {
    .third-corporate-section {
        .form-group {
            min-width: 100%;
            /* padding: 0px 30px; */
        }
    }
}