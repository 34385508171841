.auth-page-section .main-container {
    display: flex;
    flex-direction: row;
    >span{
        position: relative;
        right: 0px;
        font-size: 30px;
    }
}

.auth-page-section .heading {
    padding: 10px 20px;

    >h6 {
        color: var(--Black, #1D1D1D);
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        /* 27.659px */
        letter-spacing: -0.395px;
    }

    >p {
        color: var(--Body-text, #2D3748);
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        /* 13.83px */
        letter-spacing: -0.198px;
    }
}

.auth-page-section .content-area {
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth-page-section .content-box {
    width: 80%;

    >img {
        width: 300px;
        height: 100px;
    }
}

.auth-page-section-signup .signup-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;

    .form-label {
        color: var(--Body-text, #2D3748);
        font-size: 12 px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        /* 13.83px */
        letter-spacing: -0.198px;
    }
    >button{
        color: #FFF;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.24px;
    }
}

.login-redirect {
    padding-top: 20px; 
    margin: 0px 15px; 
    border: none;
    background-color: #FFF;
    color: #2D3748;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 16.8px */
    letter-spacing: -0.24px;

    >span {
        color: var(--Blue, #007AFF);
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: -0.24px;
    }

}

.auth-page-section-signup .form-group {
    max-width: 50%;
    padding: 10px 20px;
    /* > input{
        min-width: 500px;
    } */
}
.auth-page-section-signup .alert{
    margin: 0px 15px;
    text-align: center;
}
.auth-page-section-signup button {
    width: 100%;
    margin: 15px;
    height: auto;
    padding: 15px;
    border: none;
    border-radius: 5px;
    background: #00AEEF;
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.24px;
}
.close-button{
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
}
.auth-page-section-login .login-box {
    display: flex;
    flex-direction: column;

    .form-label {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    >button {
        min-width: 350px;
        height: 36px;
        margin-top: 20px;
        border: none;
        border-radius: 5px;
        background: #fff;
        color: #00AEEF;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    >h6 {
        padding-top: 5px;
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: right;
    }

    >h5 {
        padding-top: 10px;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: center;

        >span {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            cursor: pointer;
        }
    }

}

.auth-page-section-login .form-group {
    min-width: 350px;
    padding-bottom: 10px;
}

.auth-page-section-login {
    .login {
        color: #FFF;
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}