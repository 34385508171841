.pap-first-section .first-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 100vh;
}

@media (max-width: 576px) {

    .pap-first-section .first-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 100vh;
        padding-top: 80px;
    }

    .pap-first-section .first-container .left-container {
        margin: 0px 10px;
        padding: 40px 0px;
        min-width: 100%;

        >h2 {
            color: #FFF;
            font-size: 2rem !important;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

        }

        >h6 {
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        >p {
            color: #FFF;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    .pap-first-section .first-container .right-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 100%;
        padding: 0px 0px 40px;

        >div {
            >div {
                width: 100% !important;
                height: auto;
                border-radius: 8px;
                overflow: hidden;
                border: 1px solid var(--primary-green, #B9E500);
                background: url(<path-to-image>), lightgray 50% / cover no-repeat, #D9D9D9;
                box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;

                >img {
                    /* width: 300px;
                    height: 100%; */
                    object-fit: cover;
                }
            }

            >button {
                margin-top: 20px;
                border-radius: 8px;
                border: none;
                background: #B9E500;
                box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;
                display: flex;
                width: 100% !important;
                height: 69px;
                padding: 14px 24px;
                justify-content: center;
                align-items: center;
                color: #181818;
                text-align: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 0.32px;
            }
        }
    }
}

.pap-first-section .first-container .left-container {
    margin: 0px 10px;
    width: 50%;
    >h2 {
        color: #FFF;
        font-size: 4rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

    }

    >h6 {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    >p {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    >.details-box {

        display: flex;
        flex-direction: row;

        >.details-box-div {
            border-radius: 16px;
            border: 1px solid #3F3C4D;
            background: rgba(69, 69, 69, 0.30);
            padding: 10px;
            margin-right: 20px;

            >h5 {
                color: #FFF;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
            }

            >h4 {
                color: #FFF;
                font-size: 1.75rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
}

.pap-first-section .first-container .right-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    >div {
        >div {
            width: 578px;
            height: 311px;
            border-radius: 8px;
            overflow: hidden;
            border: 1px solid var(--primary-green, #B9E500);
            background: url(<path-to-image>), lightgray 50% / cover no-repeat, #D9D9D9;
            box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;

            >img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        >button {
            margin-top: 20px;
            border-radius: 8px;
            border: none;
            background: #B9E500;
            box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;
            display: flex;
            width: 577px;
            height: 69px;
            padding: 14px 24px;
            justify-content: center;
            align-items: center;
            color: #181818;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.32px;
        }
    }
}


/* second section  */

.pap-second-section .first-container {
    padding: 40px 0px;

    >h2 {
        color: #FFF;
        text-align: center;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}
@media (max-width: 576px) {
    .pap-second-section .first-container .features-box {
        display: flex;
        flex-direction: column !important;
        
        >div {
            margin: 10px !important;
        }
        .images{
            display: none;
        }
    }
}
.pap-second-section .first-container .features-box {
    display: flex;
    flex-direction: row;

    >div {
        margin: 40px;
    }
}

.pap-second-section .first-container .features-box .content-area {
    >h6 {
        padding-top: 10px;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    >p {
        padding-top: 10px;
        color: #6F6F6F;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}


/* third section  */
.pap-third-section {
    padding: 40px 0px;
}

.pap-third-section .first-container {
    /* padding: 40px 0px; */

    >h2 {
        color: #FFF;
        text-align: center;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}
.pap-third-section .content-area{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.pap-third-section .content-area .content-box {
    display: flex;
    flex-direction: row;
}

.pap-third-section .content-area .content-box .content-card {
    border-radius: 16px;
    border: 1px solid #3F3C4D;
    background: rgba(69, 69, 69, 0.10);
    padding: 30px 20px;
    margin: 10px;
    width: calc(33%);

    >h6 {
        color: var(--primary-green, #B9E500);
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
    }

    >p {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.pap-third-section .content-area .content-box .content-card:hover {
    border-radius: 16px;
    border: 1px solid #3F3C4D;
    background: var(--primary-blue, #00AEEF);
    backdrop-filter: blur(17px);
    display: flex;
    flex-direction: column-reverse;

    >h6 {
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
    }
}
.pap-third-section .view-more {
    
        padding: 12px 24px;
        margin: 20px 0px;
        max-width: 300px;
        border-radius: 8px;
        text-decoration: none;
        border: 1px solid var(--primary-blue, #00AEEF);
        background: #FFF;
        color: #181818;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.32px;
}
@media (max-width: 576px) {
    .pap-third-section .content-area .content-box {
        display: flex;
        flex-direction: column;
    }
    .pap-third-section .content-area .content-box .content-card {
        background-color: #00AEEF;
        min-width: calc(100% - 20px);
    }
}

/* FOurth section  */

.pap-fourth-section .first-container {
    padding: 40px 20px;


    >button {
        padding: 12px 24px;
        border-radius: 8px;
        border: 1px solid var(--primary-blue, #00AEEF);
        background: #FFF;
        color: #181818;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.32px;
        margin-left: 42%;
        position: relative;
        top: -60px;
        /* margin-right: 50%; */
    }
    >h2{
        color: #FFF;
        text-align: center;
        font-size: 3rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}

.pap-fourth-section {
    padding: 40px 0px;
}

.pap-fourth-section .first-container {
    /* background-color: #FFF; */
    position: relative;
    height: 180vh;
    width: 100%;

    /* margin: 40px 0px; */
    >img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-height: 180vh;
        object-fit: cover;
        /* Ensure the image covers the entire container */
        z-index: 0;
        /* Move the image to the background */
    }

}

.pap-fourth-section .first-container .content-area {
    margin-top: 50px;
}

.pap-fourth-section .first-container .content-area .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    /* margin-top: 100px; */
}

.pap-fourth-section .first-container .max-content-box {
    height: 280px;
    margin-top: 40px;

    >.second-content-box {
        display: none;
    }
}

.pap-fourth-section .first-container .max-content-box:hover {
    >.second-content-box {
        display: block;
    }

    >.content-box {
        display: none;
    }
}

.pap-fourth-section .first-container .second-content-box {
    position: relative;
    padding: 25px;
    width: 350px;
    height: 280px;
    border-radius: 16px;
    border-bottom: 3px solid var(--primary-green, #B9E500);
    background: var(--White, #F7F7F7);
    z-index: 2;

    >h6 {
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    >p {
        margin-top: 20px;
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.pap-fourth-section .first-container .content-box {
    position: relative;
    padding: 20px;
    z-index: 1;
    height: 140px;
    width: 350px;
    border-radius: 16px;
    border-bottom: 3px solid var(--primary-green, #B9E500);
    background: #454444;

    /* Bring the content boxes to the foreground */
    >p {
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.28px;
    }

    >h6 {
        color: #FFF;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}


@media (max-width: 576px) {
    .pap-fourth-section .first-container {
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .pap-fourth-section .first-container .content-area {
        margin-top: 0px;
    }
    .pap-fourth-section .first-container button{
        margin: 0px;
        top: 40px;
    }
    .pap-fourth-section .first-container .images{
        display: none;
    }
    .pap-fourth-section .first-container .content-area{
        display: flex;
        flex-direction: column;
    }
    .pap-fourth-section .first-container .content-area .flex-row {
        flex-direction: column !important;
    }
    .pap-fourth-section .first-container .max-content-box {
        height: fit-content;
    }

}

/* fifth section  */
.pap-fifth-section .first-container {
    display: flex;
    flex-direction: row;
    padding: 40px 20px;

    >div {
        margin: 0px 20px;

        >h2 {
            color: #FFF;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        >p {
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding-bottom: 30px;
        }
    }
}

@media (max-width: 576px) {
    .pap-fifth-section .first-container {
        display: flex;
        flex-direction: column-reverse;
        padding: 40px 10px;
    
        >div {
            margin: 0px 10px 40px 10px;
    
            >h2 {
                color: #FFF;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
    
            >p {
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                padding-bottom: 30px;
            }
            >img{
                max-width: 100%;
            }
        }
    }
}

/* sixth section  */
.pap-sixth-section {

    >h2 {
        color: #FFF;
        text-align: center;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding: 40px 0px;
    }
}

.item1 {
    grid-area: first;
}

.item2 {
    grid-area: second;
}

.item3 {
    grid-area: third;
}

.item4 {
    grid-area: fourth;
}

.item5 {
    grid-area: fifth;
}

.item6 {
    grid-area: sixth;
}

.item7 {
    grid-area: seventh;
}

.item8 {
    grid-area: eighth;
}
.item9 {
    grid-area: ninth;
}


.pap-sixth-section .grid-container {
    display: grid;
    grid-template-areas:
        'first second second second third third'
        'first fourth fourth fifth third third'
        'seventh fourth fourth sixth sixth sixth'
        'seventh eighth eighth eighth eighth eighth'
        'ninth ninth ninth ninth ninth ninth';
    gap: 10px;
    padding: 10px;
}

.pap-sixth-section .item-box {
    border-radius: 16px;
    border: 1px solid var(--primary-green, #B9E500);
    background: rgba(24, 24, 24, 0.80);
    backdrop-filter: blur(17px);
    padding: 20px;

    >h2 {
        color: #FFF;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    >p {
        color: #9A9A9A;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}
@media (max-width: 576px) {
    .pap-sixth-section .grid-container {
        display: flex;
        flex-direction: column;
        .item5{
            display: none;
        }
    }
    .pap-sixth-section .item-box {
        >h2{
            font-size: 1.25rem;
        }
        >p{
            font-size: 1rem;
        }
    }

}


/* eight section */
.pap-eight-section {
    margin: 60px 10px;

    >h2 {
        color: #FFF;
        text-align: center;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}

.pap-eight-section .first-container {
    display: flex;
    flex-direction: row;
    margin: 40px 10px;

    >div {
        margin: 0px 20px;
        >img{
            max-width: 100%;
        }
    }

    .details-box {
        >h2 {
            margin: 40px 0px 10px;
        }
    }
}

@media (max-width: 576px) {
    .pap-eight-section .first-container {
        display: flex;
        flex-direction: column;
        margin: 40px 0px;
    
        >div {
            margin: 0px ;
            >img{
                max-width: 100%;
            }
        }
        .images{
            display: none;
        }
    
        .details-box {
            >h2 {
                margin: 40px 0px 10px;
            }
        }
    }
}
/* ninth section  */

.pap-ninth-section {
    padding: 40px 0px;
}

.pap-ninth-section .first-container {
    /* margin: 60px 40px; */
    /* padding: 50px 0px; */
    border-radius: 16px;
    border: 1px solid var(--primary-green, #B9E500);
    background: #F6F6F6;
    backdrop-filter: blur(17px);
    >img{
        max-width: 100%;
    }
}

.pap-ninth-section .first-container h2 {
    text-align: center;
    color: #000;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.pap-ninth-section .first-container .first-row {

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: 30px 0px;
    height: 200px;
}

.pap-ninth-section .first-container .first-row img {
    max-height: 200px;
    width: auto;
}

.pap-ninth-section .first-container .second-row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: 50px 0px;
    height: 180px;
}

.pap-ninth-section .first-container .second-row img {
    max-height: 180px;
    width: auto;
}

@media (max-width: 576px) {
    .pap-ninth-section .first-container {
        margin: 40px 10px;
        padding: 50px 0px;
    }
    .pap-ninth-section .first-container .first-row {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        margin: 30px 0px;
        height: 100px;
    }
    .pap-ninth-section .first-container .first-row img {
        max-height: 100px;
    }
    .pap-ninth-section .first-container .second-row {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        margin: 50px 0px;
        height: 80px;
    }
    .pap-ninth-section .first-container .second-row img {
        max-height: 80px;
    }
    
}


/* tenth section  */
.pap-tenth-section .first-container {

    /* margin: 40px 0px; */
    >img {
        max-width: 100%;
    }
}

/* ELeventh Section  */
.pap-eleventh-section {
    padding: 40px 0px;
}

.pap-eleventh-section .pricing h2 {
    color: #FFF;
    font-size: 3rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
}

.pap-eleventh-section .pricing-box {
    display: flex;
    flex-direction: row;

}

.pap-eleventh-section .pricing-box .basic {
    margin: 50px;
    text-align: center;
    border-radius: 16px;
    background: #000;
    box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;

    >img {
        margin: 50px 0px 20px;
    }

    >h4 {
        margin: 20px 0px;
        color: #FFF;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    >p {
        margin: 20px 40px 50px;
        color: #FFF;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    >hr {
        background: #FFF;
        margin: 10px 20px;
    }
}

.pap-eleventh-section .pricing-box .basic .benefit-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
    margin: 10px 0px;

    >p {
        margin: 0px !important;
        padding-left: 10px;
        color: #FFF;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.pap-eleventh-section .pricing-box .basic .row-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.pap-eleventh-section .pricing-box .basic .basic-benefit {
    display: flex;
    flex-direction: column;
    padding: 0px 10px;

}

.pap-eleventh-section .pricing-box .basic .bottom-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 50px 30px;
    align-items: center;

    >h5 {
        color: #FFF;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 200;
        line-height: normal;
    }

    >button {
        border-radius: 8px;
        border: 1px solid #00AEEF;
        background: #00AEEF;
        box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;
        color: #FFF;
        text-align: center;
        font-size: 0.625 rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.24px;
        border: none;
        padding: 20px 32px;
    }
}



.pap-eleventh-section .pricing-box .mentor-led {
    margin: 50px;
    text-align: center;
    border-radius: 16px;
    background: #000;
    box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;

    >img {
        margin: 50px 0px 20px;
    }

    >h4 {
        margin: 20px 0px;
        color: #FFF;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    >p {
        margin: 20px 40px 50px;
        color: #FFF;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    >hr {
        background: #FFF;
        margin: 10px 20px;
    }
}

.pap-eleventh-section .pricing-box .mentor-led .benefit-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
    margin: 10px 0px;

    >p {
        margin: 0px !important;
        padding-left: 10px;
        color: #FFF;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

}

.pap-eleventh-section .pricing-box .mentor-led .row-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.pap-eleventh-section .pricing-box .mentor-led .mentor-led-benefit {
    display: flex;
    flex-direction: column;
    padding: 0px 10px;
}

.pap-eleventh-section .pricing-box .mentor-led .bottom-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 50px 30px;
    align-items: center;

    >h5 {
        color: #FFF;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 200;
        line-height: normal;
    }

    >button {
        border-radius: 8px;
        border: 1px solid var(--primary-green, #B9E500);
        background: #B9E500;
        box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;
        color: #FFF;
        text-align: center;
        font-size: 0.625 rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.24px;
        border: none;
        padding: 20px 32px;
    }
}


@media (max-width: 576px) {
    .pap-eleventh-section .pricing-box {
        display: flex;
        flex-direction: column;
    
    }
    .pap-eleventh-section .pricing-box .basic {
        margin: 10px;
    }
    .pap-eleventh-section .pricing-box .mentor-led {
        margin: 10px;
    }

    .pap-eleventh-section .pricing-box .basic .bottom-box {
        padding: 10px 20px;
    }

    .pap-eleventh-section .pricing-box .mentor-led .bottom-box {
        padding: 10px 20px;
    }
}



/* twelfth section */

.pap-twelfth-section {
    margin: 40px 0px;
}

.pap-twelfth-section .first-container {
    display: flex;
    flex-direction: row;

    >div {
        margin: 20px;

        >h2 {
            color: #FFF;
            font-size: 48px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        >p {
            margin-top: 20px;
            color: #B3B3B3;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        >button {
            margin-top: 20px;
            border-radius: 8px;
            border: 1px solid #00BCBD;
            background: #000;
            padding: 20px 40px;
            color: #DAEBE8;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.32px;
        }

        >img {
            width: 300px;
            height: 380px;
            border-radius: 15px;
            border: 1px solid #000;
        }

        >h6 {
            color: #FFF;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }
}