.navbar-nav li:hover>ul.dropdown-menu {
  display: block;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu>.dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
}

.black-navbar .dropdown-item:hover{
  background-color: #555;
}

@media screen and (max-width: 600px) {

  .dropdown-submenu>.dropdown-menu {
    top: 10;
    /* left: 100%; */
    /* margin-top: -6px; */
  }
}

.navbar-light .dropdown-menu {
  padding: 20px 10px;
  border-radius: 15px;
  border-bottom: 2px solid #B9E500;
  background: #FFF;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.25);

  >li {
    padding: 5px 0px;
  }

}

/* rotate caret on hover */
.dropdown-menu>li>a:hover:after {
  text-decoration: underline;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

/* Navbar.css */

/* Navigation bar styles */
.navbar {
  background-color: #ffffff !important;
  /* overflow: hidden; */
  padding: 10px 0px;
  /* display: flex;
    justify-content: space-between;
    align-items: center; */
  color: #000;

  /* font-family: Raleway; */
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}


#navbarSupportedContent ul {
  align-items: center;
  >li {
    /* margin-left: 1rem;
    margin-right: 1rem; */
  }
}

.navbar-li {
  /* margin-left: 0.6rem;
  margin-right: 0.6rem; */
}
@media screen and (min-width: 600px) {
.navbar-nav-ul{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

}

.navbar-dark .dropdown-menu {
  background-color: #000;
  padding: 20px 10px;
  border-radius: 15px;
  border-bottom: 2px solid #B9E500;
  background: rgb(43, 43, 43);
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.25);

  >li {
    padding: 5px 0px;
  }
}

#navbarSupportedContent li a {
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* Logo styles */
.logo {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
}

.dropdown-flex {
  display: flex;
  flex-direction: row;
}

.logo img {
  width: 160px;
  height: 46px;
  flex-shrink: 0;
}

/* Navigation links styles */
.nav-links {
  display: flex;
}

.navbar a {
  color: #000000;
  text-decoration: none;
  transition: background-color 0.3s;
}

.nav-links a:hover {
  background-color: #555;
}

/* Sign-up button styles */
.signup-btn {
  /* color: white;
    text-decoration: none;
    padding: 10px 20px;
    background-color: #4CAF50;
    border-radius: 5px;
    transition: background-color 0.3s; */
  display: flex;
  width: 134px;
  height: 44px;
  padding: 14px 24px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #00AEEF;
  color: #FFF !important;
  text-align: center;
  /* font-family: Poppins; */
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.32px;
}

.header-logo {
  height: 60px;
  width: 170px;
}

/* .signup-btn:hover {
    background-color: #45a049;
  } */

/* Responsive styles */
@media screen and (max-width: 600px) {
  .navbar {
    flex-direction: row;
    align-items: center;
    padding: 10px !important;
  }

  .nav-links {
    margin-top: 10px;
    justify-content: flex-start;
  }

  .nav-links a {
    margin: 0;
    width: 100%;
    box-sizing: border-box;
  }
}

.black-navbar {
  background-color: rgb(0, 0, 0, 0) !important;
}

.black-navbar #navbarSupportedContent li a {
  color: #FFF !important;
}

.black-navbar .signup-btn {
  border-radius: 8px;
  border: 1px solid #00AEEF;
  border: 1px solid var(--primary-blue, #00AEEF);
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.32px;
}
@media screen and (min-width:600px) {
  #navbarBlack {
    position: absolute;
    z-index: 999999;
  }
}


@media screen and (max-width: 600px) {
  #navbarSupportedContent{
    justify-content: center;
    align-items: center;
  }
  .navbar-nav{
    >li{
      padding: 5px !important;
      margin: 0px !important;
    }
    /* background-color: #FFF !important; */
    /* position: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between; */
  }
}

.login-profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  >h6 {
    padding-top: 0.25rem;
    padding-left: 0.5rem;
    text-transform: capitalize;
    color: #000;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;
  }
}
.login-profile-dark {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  >h6 {
    padding-top: 0.25rem;
    padding-left: 0.5rem;
    text-transform: capitalize;
    color: #FFF;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;
  }
}

@media (max-width: 576px) {
    .hide-in-mobile{
        display: none !important;
    }

}

.bg-color-f0f0f0 {
    background-color: #F0F0F0;
    margin-top: 40px 0px;
    padding: 30px 0px;
    border-top: 1px solid #F6F6F6;
    border-bottom: 1px solid #F6F6F6;
}


/* First Section  */
.left-card-box h1 {
    color: #181818;
    /* font-family: Raleway; */
    font-size: 4rem;
    font-style: normal;
    font-weight: 600;
    line-height: 84px;
    /* 131.25% */
}

.left-card-box h4 {
    margin-top: 20px;
    color: #181818;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    width: 70%;
    white-space: normal;
    text-overflow: initial;
}

.left-card-box button {
    border-radius: 8px;
    border: 2px solid #00AEEF;
    border: 2px solid var(--primary-blue, #00AEEF);
    background: #FFF;
    padding: 10px 30px;
    margin-top: 40px;
    color: #181818;
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;
}

.right-card-box {
    margin: 50px 0px;
}

/* .circle-parent-container{
    background-color: #3498db;
} */
.circle-container {
    display: flex;
    align-items: center;
    margin-top: 30px;
    /* justify-content: center; */
}

.circle {
    width: 60px;
    height: 60px;
    background-color: #3498db;
    border-radius: 50%;
    margin-right: -30px;
    position: relative;
    z-index: 1;
    border: 2px solid #B9E500;
    border: 2px solid var(--primary-green, #B9E500);
    background: url(<path-to-image>), lightgray 50% / cover no-repeat;
}

.circle:nth-child(2) {
    width: 60px;
    height: 60px;
    background-color: #e74c3c;
    z-index: 2;
    margin-right: -30px;
    border: 2px solid #B9E500;
    border: 2px solid var(--primary-green, #B9E500);
    background: url(<path-to-image>), lightgray 50% / cover no-repeat;
}

.circle:nth-child(3) {
    width: 60px;
    height: 60px;
    background-color: #2ecc71;
    z-index: 3;
    border: 2px solid #B9E500;
    border: 2px solid var(--primary-green, #B9E500);
    background: url(<path-to-image>), lightgray 50% / cover no-repeat;
}

.circle-text {
    color: #667085;
    color: var(--Gray-500, #667085);
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    margin-left: 40px;
}

.main-first-section {
    min-height: 100vh;
    padding-top: 50px;
}


@media (max-width: 576px) {

    .main-first-section {
        min-height: 80vh;
        /* width: "100%"; */
    }

    .left-card-box {
        text-align: center;

        >h4 {
            width: 100%;
        }
    }

}

.second-section {
    background-color: #F6F6F6;
}

.banner {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 160px;
    background-color: #000;
    padding: 0px 80px;
}

.banner>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 10px;
}

.banner>div h4 {
    color: #FFF;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.banner>div h6 {
    color: #FFF;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 112.5% */
}

.banner>span {
    margin: 0 10px;
    font-size: 2.5rem;
    /* font-weight: bold; */
    color: #B9E500;
    /* Adjust the color as needed */
}

@media (max-width: 576px) {

    .banner {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 100px;
        background-color: #000;
        padding: 0px 20px;
    }

    .banner>div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 0 5px;
    }

    .banner>div h4 {
        color: #FFF;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }

    .banner>div h6 {
        color: #FFF;
        font-size: 0.5rem;
        font-style: normal;
        font-weight: 200;
        line-height: 9px;
        /* 112.5% */
    }

    .banner>span {
        margin: 0 5px;
        font-size: 1.25rem;
        /* font-weight: bold; */
        color: #B9E500;
        /* Adjust the color as needed */
    }
}





/* Company Banner  */


.company-banner {
    text-align: center;
    min-height: 210px;
    background: #FFF;
    box-shadow: -5px -5px 10px 0px rgba(0, 0, 0, 0.25) inset, 5px 5px 10px 0px rgba(0, 0, 0, 0.25) inset;
}

.company-banner>div {
    display: flex;
    justify-content: space-around;
}

.company-banner>p {
    color: #B9E500;
    /* Adjust the color as needed */
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 30px 0px 20px;
}

/* Third Section */

.third-section h2 {
    margin: 0px 0px 50px;
    text-align: center;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.third-section .content-area {
    display: flex;
    flex-direction: row;

}


.third-section .left-box {
    margin: 0px 30px;
}

.third-section .right-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}


.third-section .card-box-head {
    line-height: 1;
}

.third-section .right-box>div {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(50% - 20px);
    padding: 25px;
    margin-bottom: 40px;
    border-radius: 8px;
    border: 2px solid #B9E500;
    border: 2px solid var(--primary-green, #B9E500);
    background: #FFF;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset, -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset;
}

.third-section .right-box div div {
    display: flex;
    justify-content: space-between;
}

.third-section .right-box>div div div p {
    /* display: flex;
    align-items: center;
    line-height: 0; */
    color: #181818;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1;
}

.third-section .right-box>div h4 img {
    margin-right: 10px;
    max-width: 100px;
    max-height: 83px;
    flex-shrink: 0;
}

.third-section .right-box p {
    margin: 0;
}

.third-section .card-box {
    height: 280px;
}

.third-section .card-box:hover {
    border-radius: 8px;
    border: 3px solid #B9E500;
    border: 3px solid var(--primary-green, #B9E500);
    background: #D8FFAC;
    box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;
    >.card-box-content {
        width: 100%;
        white-space: normal;
        text-overflow: initial;
    }

    >.card-box-content p {
        padding-top: 10px;
        color: #000000;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

}

.third-section .card-box-content {
    width: 70%;
    /* overflow: hidden; */
    white-space: normal;
    text-overflow: initial;
    /* transition: width 0.5s ease;  */
}

.third-section .card-box-content p {
    color: #717171;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

@media (max-width: 576px) {
    .third-section .content-area {
        display: flex;
        flex-direction: column-reverse;
    }

    .third-section .right-box>div {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(100% - 20px);
        padding: 25px;
        margin: auto;
        margin-bottom: 40px;
        border-radius: 16px;
        border: 2px solid #B9E500;
        border: 2px solid var(--primary-green, #B9E500);
        background: #FFF;
        box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;
    }

    .third-section .card-box-content {
        width: 100%;
        margin-top: 20px;
    }

    .third-section .left-box {
        display: none;
    }
}

/* Fourth Section  */
.fourth-section {
    /* background-color: #F6F6F6; */
}

.fourth-card-box {
    display: flex;
    margin-bottom: 80px;
}

@media (max-width: 576px) {
    .fourth-card-box {
        display: flex;
        flex-direction: column;
        margin-bottom: 80px;
    }

    .fourth-card-content-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 20px;

        >p {
            font-size: 1.25rem !important;
            width: 100% !important;
        }

        >h3 {
            font-size: 2rem !important;
        }
    }

    .fourth-card-content-box-image {
        display: flex;
        justify-content: center;
    }
}

.fourth-card-content-box-image img {
    width: 90%;
}

.fourth-card-box>div h3 {
    width: 80%;
    /* overflow: hidden; */
    white-space: normal;
    text-overflow: initial;
    color: #000;
    /* font-family: Raleway; */
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    >.first-span {
        color: #00AEEF;
        color: var(--primary-blue, #00AEEF);
        font-family: Raleway;
        font-size: 3rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    >.second-span {
        color: #B9E500;
        color: var(--primary-green, #B9E500);
        font-family: Raleway;
        font-size: 3rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}

.fourth-card-box>div p {
    margin-top: 10px;
    width: 90%;
    /* overflow: hidden; */
    white-space: normal;
    text-overflow: initial;
    color: #181818;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.fourth-card-box>div button {
    margin-top: 40px;
    border-radius: 8px;
    border: 2px solid #00AEEF;
    border: 2px solid var(--primary-blue, #00AEEF);
    background: #FFF;


    color: #181818;
    text-align: center;
    padding: 10px 30px;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;
}

.fourth-card-content-box {
    margin: 0px 20px;
}

/* Fifth Section  */

.fifth-section .courses-area .details-box {
    margin: 40px 0px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 100%;
}

.fifth-section .courses-area .details-box .box {
    display: flex;

    >div {
        >p {
            margin-bottom: 10px;
        }
    }
}

.fifth-section .courses-area .details-box .box div {
    margin-left: 10px;
}

.courses-box {
    float: right;
    border-radius: 16px;
    background: #FFF;
    box-shadow: -3px -3px 5px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 5px 0px rgba(0, 0, 0, 0.25) inset;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    padding: 10px;
}

.courses-button {
    border-radius: 8px;
    color: #070707;
    border: none;
    font-size: 1.125rem;
    background: #FFF;
    font-style: normal;
    padding: 15px 10px;
    font-weight: 600;
    line-height: 28px;
    /* 155.556% */
}

.courses-button.active {
    border-radius: 8px;
    border: none;
    background: #B9E500;
    background: var(--primary-green, #B9E500);
    box-shadow: -3px -3px 10px 0px rgba(16, 24, 40, 0.05) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;
    color: #070707;
    padding: 15px 10px;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    /* 155.556% */
}

.courses-area h2 {
    color: #000;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
}

.courses-area .details-box {
    max-width: 100%;
}

/* .courses-parent-box{
    max-width: 100%;
   
} */
.course-parent-box {
    margin-top: 160px;
    /* background-color: #00AEEF;
    display: flex;
    width: calc(33% - 20px); */
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
}

.course-box {
    border-radius: 16px;
    border: 1px solid rgba(55, 58, 63, 0.30);
    background: #FFF;
    margin: 0px 20px 50px;
    width: calc(33.3333% - 40px);

}

.course-box div {
    margin: 20px 10px;
}


.course-box div div span {
    border-radius: 8px;
    background: #D8FFAC;
    background: var(--secondary-green, #D8FFAC);
    padding: 5px 10px;
}

.course-box img {
    width: calc(100% - 20px);
    margin: 10px 10px 10px 10px;
    border-radius: 16px 16px 0px 0px;
    /* background: url(<path-to-image>), lightgray 50% / cover no-repeat; */
}

.course-box h4 {
    color: #000;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    /* 112.5% */
}

.course-box h5 {
    color: #000;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    /* 125% */
}

.course-box-mentor {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 !important;
}

.course-box-mentor div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
    margin: 0 !important;
    min-width: 50%;
    padding-right: 10px;
    border-right: 1px solid black;
}

.course-box-mentor img {
    margin: 0 !important;
    height: 50px;
    width: 50px;
}

.course-box-mentor div h6 {
    color: #000;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    /* 100% */
}

.course-box-mentor div p {
    color: #000;
    font-size: 0.825rem;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
}

.course-box-mentor h5 {
    color: #000;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    /* 150% */
}

.course-box-footer {
    margin: 0 !important;
    display: flex;
    justify-content: space-between;
}

.explore-button-div {

    margin-bottom: 50px;
}

.explore-button-div button {
    border: none;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    border-radius: 8px;
    background: #B9E500;
    background: var(--primary-green, #B9E500);
    box-shadow: -3px -3px 5px 0px rgba(16, 24, 40, 0.05) inset, 3px 3px 5px 0px rgba(0, 0, 0, 0.25) inset;
    display: flex;
    width: 304px;
    height: 72px;
    padding: 16px 28px;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

@media (max-width: 576px) {

    .fifth-section .courses-area .details-box .box {
        display: flex;
        justify-content: space-around;

        >img {
            height: 30px;
            width: 30px;
        }

        >div {
            >p {
                font-size: 8px;
                margin-bottom: 0px;
            }

            >h6 {
                font-size: 12px;
            }
        }
    }

    .fifth-section .courses-parent-box .courses-button {
            font-size: 0.75rem;
            background: #FFF;
            padding: 5px 5px;

        }
        .fifth-section .courses-button.active {
            border-radius: 8px;
            border: none;
            background: #B9E500;
            background: var(--primary-green, #B9E500);
            box-shadow: -3px -3px 10px 0px rgba(16, 24, 40, 0.05) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;
            color: #070707;
            padding: 5px 5px;
            font-size: 0.75rem;

        }

        .fifth-section .course-parent-box{
            display: flex;
            flex-direction: column;
        }
    
        .fifth-section .course-box {
            border-radius: 16px;
            border: 1px solid rgba(55, 58, 63, 0.30);
            background: #FFF;
            margin: 0px 10px 30px;
            width: calc(100% - 20px);
        
        }

}






/* .play{
    padding: 5px 5px;
    border-radius: 50%;
} */


/* Sixth Section  */

.sixth-section .discover-heading {
    margin: 40px 0px;
    text-align: center;
}

.sixth-section .discover-heading h3 {
    color: #000;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.sixth-section .discover-card-box {
    text-align: center;
    align-items: center;
    justify-content: center;
    /* display: flex; */
}

.sixth-section .discover-card-box {
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 100px;
}

.sixth-section .discover-card-box .disc-card {
    padding: 10px;
    height: 430px;
    max-width: 320px;
    border-radius: 20px;
    border: 2px solid #B9E500;
    border: 2px solid var(--primary-green, #B9E500);
    background: rgba(216, 255, 172, 0.30);
    box-shadow: -3px -3px 10px 0px rgba(0, 27, 111, 0.20) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;
}

.sixth-section .discover-card-box div img {
    height: 180px;
    margin: 30px auto;
}

.sixth-section .slick-dots li button:before {
    font-size: 1.25rem;
    line-height: 20px;
    margin-top: 70px;
    left: -30px;
    color: #B9E500;
    color: var(--primary-green, #B9E500);
}



.sixth-section .slick-dots li button:focus {
    color: #B9E500;
    color: var(--primary-green, #B9E500);
}

.sixth-section .discover-card-box div h6 {
    color: #000;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.sixth-section .discover-card-box div p {
    color: #45494E;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media (max-width: 576px) {
    .sixth-section .discover-card-box {
        margin: 5px !important;
    }

    .sixth-section .discover-card-box .disc-card {
        margin: 10px;
        width: 400px !important;
        overflow: hidden;
    }

    .sixth-section .slick-dots li button:before {
        left: 0px;
    }

    .sixth-section .slick-prev:before,
    .slick-next:before {
        color: #B9E500;
    }
}






/* Seventh Section  */

.testimonial-section .testimonial-heading {
    margin: 40px 0px;
}

.testimonial-section .testimonial-heading div {}

.testimonial-section .testimonial-heading h6 {
    width: 60%;
    margin: 0px auto;
    white-space: normal;
    text-overflow: initial;
    color: #070707;
    text-align: center;
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    line-height: 62px;
    /* 129.167% */

}

.testimonial-section .testimonial-card {
    /* margin: 0px 30px; */
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    height: 330px;
    /* Set a fixed height for vertical scrolling */
    overflow-y: auto;
    /* Enable vertical scrolling */
}

.testimonial-card::-webkit-scrollbar {
    width: 10px;
    /* Set the width of the scrollbar */
}

.testimonial-card::-webkit-scrollbar-thumb {
    background-color: #00AEEF;
    /* Set the color of the scrollbar thumb */
    border-radius: 4px;
    /* Optional: Add some border-radius for a rounded appearance */
}


.testimonial-section .testimonial-card>div {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(33.3333% - 20px);
    margin: 10px;
    padding: 20px;
    border-radius: 16px;
    background: #00AEEF;
    background: var(--primary-blue, #00AEEF);
    box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;
    width: 420px;
    height: 310px;
}

.testimonial-section .testimonial-card p {
    margin-top: 20px;
    color: #FFF;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 137.5% */
}

.testimonial-section .testimonial-card .profile {
    margin-top: 20px;
    display: flex;
    align-items: center;
}

.testimonial-section .testimonial-card .profile div {
    margin: 20px;
}

.testimonial-section .testimonial-card .profile div h4 {
    color: #FFF;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    /* 122.222% */
}

.testimonial-section .testimonial-card .profile div h5 {
    color: #070707;
    font-size: 0.825rem;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 157.143% */
}

@media (max-width: 576px) {
    .testimonial-section .testimonial-heading h6 {
        width: 90%;
    }

    .testimonial-section .testimonial-card>div {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(100% - 20px);
        padding: 20px;
        height: 310px;
    }

}

/* Eigth Section  */
.eigth-section .chat-box {
    margin: 70px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.eigth-section .chat-box .contact {
    height: 600px;
    width: calc(50% - 100px);
    padding: 50px;
    border-radius: 16px;
    background: linear-gradient(204deg, #D8FFAC 20.1%, rgba(0, 174, 239, 0.46) 84.77%);
    box-shadow: -3px -3px 5px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 5px 0px rgba(0, 0, 0, 0.25) inset;
}

.eigth-section .chat-box .contact h5 {
    color: #181818;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.56px;
}

.eigth-section .chat-box .contact p {
    color: #707070;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.eigth-section .chat-box .contact .flex {
    display: flex;
    margin: 30px 10px;
}

.eigth-section .chat-box .contact .flex div {
    margin: 0px 10px;
    line-height: 1;
}

.eigth-section .chat-box .contact .flex div h6 {
    line-height: 1;
}

.eigth-section .chat-box .contact .social {
    display: flex;
    margin-top: 9rem;
}

.eigth-section .chat-box .contact .social div img {
    width: 18px;
    height: 18px;
    margin: 7px;
}

.eigth-section .chat-box .contact .social div {
    margin: 10px;
    padding: 0px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.40);
}

.eigth-section .chat-box .connect {
    height: 600px;
    width: calc(50% - 100px);
    padding: 0px 50px 50px;
    > div{
        height: 100%;
    }
}

.eigth-section .chat-box .connect label {
    margin: 10px 0px;
    color: #C7C7C7;
    font-size: 0.825rem;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
}

.eigth-section .chat-box .connect button {
    bottom: 0;
    width: 100%;
    border-radius: 8px;
    border: none;
    background: #B9E500;
    background: var(--primary-green, #B9E500);
    box-shadow: -3px -5px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;

    display: flex;
    /* width: 414px; */
    /* height: 48px; */
    color: #000;
    padding: 14px 24px;
    justify-content: center;
    align-items: center;
    grid-gap: 6px;
    gap: 6px;
    font-weight: 500;
}

.rating-star i {
    color: #FFF;
    margin: 5px;
}

@media (max-width: 576px) {
    .eigth-section .chat-box {
        flex-direction: column;

        >.contact {
            min-width: 100%;
        }

        >.connect {
            min-width: 100%;
            padding: 20px 0px;
        }
    }
    .eigth-section .chat-box .contact .social {
        display: flex;
        margin-top: 5rem;
        justify-content: center;
    }

}
.show-in-mobile{
    display: none;
}


@media (max-width: 576px) {
    .show-in-mobile{
        display: flex !important;
        flex-direction: column;
    }
    .hide-in-mobile{
        display: none;
    }
}



.newsletter {
    background: #000;
}

.newsletter .newsletter-area {
    padding: 30px 0px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
}

.newsletter .newsletter-area .first {
    >h6 {
        color: #FFF;
        color: var(--White, #FFF);

        /* Text xl/Medium */
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
        /* 150% */
    }

    >p {
        color: #B3B3B3;

        /* Text md/Normal */
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 150% */
    }
}

.newsletter .newsletter-area .second input {
    width: 400px;
    padding: 12px 16px;
    align-items: center;
    grid-gap: 12px;
    gap: 12px;
    background-color: #000;
    color: #FFF;
    border-radius: 8px;
    border: 1px solid #707070;

    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.newsletter .newsletter-area .second button {
    /* display: flex; */
    margin: 0px 10px;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #00AEEF;
    border: 1px solid var(--primary-blue, #00AEEF);
    background-color: #000;
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: #FFF;
    color: var(--White, #FFF);
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
}



@media (max-width: 576px) {
    .newsletter .newsletter-area {
        padding: 30px 20px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;
    }

    .newsletter .newsletter-area .first {}

    .newsletter .newsletter-area .second .input-box {
        width: 200px;
    }

}

.main-footer {
    background: #181818;
}

.main-footer .first-container {
    padding: 50px 0px;
    display: flex;
    /* flex-direction: row; */
    /* align-items: center; */
    justify-content: space-around;
}


.main-footer .first-container p {
    color: #B3B3B3;
    /* Text md/Normal */
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
}

.main-footer .first-container .first {
    width: 40%;
}

.main-footer .first-container .first img {
    width: 260px;
    height: 120px;
}

.main-footer .first-container h5 {
    width: 80%;
    white-space: normal;
    text-overflow: initial;
    color: #B3B3B3;

    /* Text md/Normal */
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
}

.main-footer .first-container h6 {
    color: #F0F0F0;

}
.main-footer .first-container .third h6{
    padding-bottom: 5px;
}
.main-footer .first-container .second h6{
    padding-bottom: 5px;
}
.main-footer .first-container .fourth h6{
    padding-bottom: 5px;
}

.main-footer hr {
    background: #00AEEF;
}

.main-footer .second-container {
    padding-bottom: 40px;
}

.main-footer .second-container h5 {
    color: #98A2B3;
    color: var(--Gray-400, #98A2B3);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.main-footer .second-container div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main-footer .second-container .social {
    display: flex;
    align-items: center;
}

.main-footer .second-container .social img {
    margin: 0px 10px;
}


@media (max-width: 576px) {
    .main-footer .first-container {
        flex-direction: row;
    }
    .main-footer .show-in-mobile{
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;
       >h5{
        color: #F0F0F0;
        margin: 10px;
       }
    }
    .main-footer .show-in-mobile img{
        width: 90%;
        padding: 10px 0px;
    }
    .main-footer .first-container .first {
        width: 100%;
        align-items: center;
        justify-content: space-around;
        >img {
            width: 90%;
        }

        >h5 {
            padding: 20px;
            color: white;
        }
    }

    .main-footer .first-container .flex {
        display: flex;
        justify-content: space-around;
    }

    .main-footer .second-container .social-box {
        flex-direction: column;
    }
}

.main-footer a{
    color: #FFF !important;
    text-decoration: none;
}
@media (max-width: 576px) {
    .show-in-mobile {
        display: flex;
    }

    .hide-in-mobile {
        display: none;
    }
}

.bg-color-f0f0f0 {
    background-color: #F0F0F0;
    margin-top: 40px 0px;
    padding: 30px 0px;
    border-top: 1px solid #F6F6F6;
    border-bottom: 1px solid #F6F6F6;
}

.bg-color-161515 {
    background-color: #161515;
    margin-top: 40px 0px;
    padding: 30px 0px;
}

/* Testimonials  */

/* Rating Star Css */
.rating-star i {
    color: #FFF;
    margin: 5px;
}

.orange-rating-star {
    margin: 5px !important;
}

.orange-rating-star i {
    color: orange;
    margin: 3px;
}

.dark-testimonial-section {
    background: #000;
    padding: 60px 0px;
}


.dark-testimonial-section .testimonial-heading {
    margin-bottom: 40px;
}

.dark-testimonial-section .testimonial-heading h6 {
    width: 60%;
    margin: 0px auto;
    white-space: normal;
    text-overflow: initial;
    color: #FFF;
    text-align: center;
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    line-height: 62px;
    /* 129.167% */

}

.dark-testimonial-section .testimonial-card {
    /* margin: 0px 30px; */
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    height: 330px;
    /* Set a fixed height for vertical scrolling */
    overflow-y: auto;
    /* Enable vertical scrolling */
}

.testimonial-card::-webkit-scrollbar {
    width: 10px;
    /* Set the width of the scrollbar */
}

.testimonial-card::-webkit-scrollbar-thumb {
    background-color: #00AEEF;
    /* Set the color of the scrollbar thumb */
    border-radius: 4px;
    /* Optional: Add some border-radius for a rounded appearance */
}

.dark-testimonial-section .testimonial-card>div {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(33.3333% - 20px);
    margin: 10px;
    padding: 20px;
    border-radius: 16px;
    background: #00AEEF;
    background: var(--primary-blue, #00AEEF);
    box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;
    width: 420px;
    height: 320px;
    position: relative;
}

.dark-testimonial-section .testimonial-card p {
    margin-top: 20px;
    color: #FFF;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 137.5% */
}

.dark-testimonial-section .testimonial-card .profile {
    /* margin-top: 30px; */
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 0;
}

.dark-testimonial-section .testimonial-card .profile div {
    margin: 20px;
}

.dark-testimonial-section .testimonial-card .profile div h4 {
    color: #FFF;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    /* 122.222% */
}

.dark-testimonial-section .testimonial-card .profile div h5 {
    color: #070707;
    font-size: 0.825rem;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 157.143% */
}

@media (max-width: 576px) {
    .dark-testimonial-section .testimonial-heading h6 {
        width: 90%;
    }

    .dark-testimonial-section{
        max-width: 90%;
    }
    
    .dark-testimonial-section .testimonial-card>div {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(100% - 20px);
        padding: 20px;
        height: 310px;
    }

}
/* Banner Logo  */
.banner-container{
    max-width: 100%;
    .slick-arrow{
        display: none !important;
    }
}
.banner-image{
    width: 130px;
    >img{
        max-width: 130px;
        height: auto;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 576px) {
    .banner-container{
        max-width: 90%;
        .slick-arrow{
            display: none !important;
        }
    }
    .banner-image{

        margin: 20px 0px;
        width: 130px;
        >img{
            max-width: 130px;
            height: auto;
        }
    }

}

/* FAQ */
.faq-parent-div {
    /* margin: 50px 0px; */
    text-align: center;
}

.faq-div {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    text-align: left;
    /* align-items: center; */
}


.faq-div .col-md-8 {
    margin: 40px 40px;
}

.faq-div .col-md-8 h6 {
    margin-top: 2rem;
    color: #000;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.faq-div .col-md-8 p {
    color: #45494E;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.faq-div .col-md-8 .contact-us {
    color: #181818;
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.24px;
    border-radius: 8px;
    background: #B9E500;
    background: var(--primary-green, #B9E500);
    box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;
    border: none;
    width: 170px;
    height: 55px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
}


.faq-image-div {
    margin: 40px 0px;
    
    >img{
        max-width: 100%;
    }
}

#card-q-a {
    margin-bottom: 10px;
    background-color: #FFF;
    border-radius: 16px !important;
    border: none;
}

#card-q-a .card-header {
    background-color: #FFF;
    border-bottom: none;
}

#card-q-a button {
    color: #000;
    color: var(--Text-txt-primary, #000);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
}

#card-q-a .card-body {
    color: rgba(60, 60, 67, 0.85);
    color: var(--Text-txt-secondary, rgba(60, 60, 67, 0.85));
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 25.2px */
}

@media (max-width: 576px) {
    .faq-div {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        text-align: left;
        /* align-items: center; */
    }

    .faq-div .col-md-8 {
        margin: 10px 0px;
    }

    .faq-image-div {
        display: none;
        margin: 40px 0px;
    }

    #card-q-a button {
        color: #000;
        color: var(--Text-txt-primary, #000);
        font-size: 1rem;
        text-align: left;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration: none;
    }

    #card-q-a .card-body {
        color: rgba(60, 60, 67, 0.85);
        color: var(--Text-txt-secondary, rgba(60, 60, 67, 0.85));
        font-size: 0.85rem;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        /* 25.2px */
    }
}


/* Peer Learning */

.peer-learning {
    >h1 {
        color: #FFF;
        text-align: center;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    >h4 {
        color: #C7C7C7;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}

.peer-learning .learning-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.peer-learning .learning-box .learning-card {
    width: 340px;
    margin: 20px;
    min-height: 260px;
    padding: 40px;
    border-radius: 16px;
    border: 1px solid #00AEEF;
    border: 1px solid var(--primary-blue, #00AEEF);
    background: #F4F4F4;
    box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;

    >p {
        color: #343434;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.peer-learning .learning-box .learning-card .flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    >h6 {
        color: #070707;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.48px;
    }

}

@media (max-width: 576px) {
    .peer-learning .learning-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

/* Grow  */
.grow {
    padding: 50px;

    >h3 {
        color: #FFF;
        font-size: 4rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    >h4 {
        color: #FFF;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 20px;
    }
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.grow .border-design {
    border-radius: 16px;
    border: 1px solid #3F3C4D;
    background: rgba(24, 24, 24, 0.30);
    padding: 30px;
    margin: 10px;
}

.grow .first-column {
    width: 6000px;
}

.grow .grow-content h6 {

    color: #FFF;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 10px;

}

.grow .grow-content img {
    float: right;
    position: relative;
}

.grow .grow-content p {
    color: #FFF;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
}

.grow-bottom-box {
    display: flex;
    flex-direction: row;
    padding: 20px;
}

.grow-bottom-box div {

    >h4 {
        color: #FFF;
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    >h5 {
        margin-top: 30px;
        color: #FFF;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 250;
        line-height: normal;
    }
}

.grow-bottom-div {
    max-width: calc(80%-20px);
}


.grow-bottom-card-white {
    border-radius: 14px;
    background: #F7F7F7;
    background: var(--White, #F7F7F7);
    max-width: 260px;
    min-height: 350px;
    padding: 20px;
    margin: 10px;

    >h6 {
        color: #000;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    >p {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.grow-bottom-card-green {
    border-radius: 14px;
    background: #B9E500;
    background: var(--primary-green, #B9E500);
    max-width: 260px;
    min-height: 350px;
    padding: 20px;
    margin: 10px;

    >h6 {
        color: #000;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    >p {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.slick-dots li button:before {
    top: -60px;
    left: -640px;
    color: white;
    font-size: 1.25rem;
    line-height: 20px;
}   

.slick-dots li button:focus {
    color: white;
}


@media (max-width: 576px) {
    .grow {
        padding: 10px;
    }

    .grow .flex-row {
        display: flex;
        flex-direction: column !important;
    }

    .grow .first-column {
        width: 100%;
    }

    .grow-bottom-box {
        display: flex;
        flex-direction: column;
    }

    .grow-bottom-div {
        width: 100%;
    }

    .slick-dots li button:before {
        /* top: -60px;
        left: -770px; */
        color: white;
        color: var(--primary-green, white);

    }

    .grow-bottom-card-white {
        border-radius: 14px;
        background: #F7F7F7;
        background: var(--White, #F7F7F7);
        max-width: calc(100% -10px);

        /* min-height: 350px;
        padding: 20px;
        margin: 10px; */

        >h6 {
            color: #000;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        >p {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    .grow-bottom-card-green {
        border-radius: 14px;
        background: #B9E500;
        background: var(--primary-green, #B9E500);
        max-width: calc(100% -10px);
        /* min-height: 350px;
        padding: 20px;
        margin: 10px; */

        >h6 {
            color: #000;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        >p {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

}


/* Unique */

.unique {
    padding: 50px 0px;

    >h6 {
        text-align: center;
        color: #FFF;
        text-align: center;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}

.unique-content-area {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .images {
        margin-top: 90px;
    }
}

.unique-content {
    padding: 20px;
    margin: 30px;
    border-radius: 16px;
    border: 1px solid #B9E500;
    background: linear-gradient(0deg, rgba(24, 24, 24, 0.70) 0%, rgba(24, 24, 24, 0.70) 100%), url(<path-to-image>), lightgray 50% / cover no-repeat;

    >p {
        color: #DADADA;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-top: 2px;
    }
}

.unique-content-area .second-box {
    margin-top: 80px;

}

.unique-content .content-header-white {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    padding: 20px;
    margin-bottom: 10px;

    border-radius: 16px;
    background: #FFF;
}

.unique-content .content-header-blue {
    color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    padding: 20px;
    margin-bottom: 10px;

    border-radius: 16px;
    background: #00AEEF;
    background: var(--primary-blue, #00AEEF);
}

@media (max-width: 576px) {
    .unique-content-area {
        display: flex;
        flex-direction: column !important;
    }

    .unique-content-area .second-box {
        margin-top: 0px;

    }

    .unique-content {
        padding: 20px;
        margin: 30px;
        border-radius: 16px;
        border: 1px solid #B9E500;
        background: linear-gradient(0deg, rgba(24, 24, 24, 0.70) 0%, rgba(24, 24, 24, 0.70) 100%), url(<path-to-image>), lightgray 50% / cover no-repeat;

        >p {
            color: #DADADA;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding-top: 2px;
        }
    }

    .unique-content .content-header-white {
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.48px;
        padding: 20px;
        margin-bottom: 10px;

        border-radius: 16px;
        background: #FFF;
    }

    .unique-content .content-header-blue {
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.48px;
        padding: 20px;
        margin-bottom: 10px;

        border-radius: 16px;
        background: #00AEEF;
        background: var(--primary-blue, #00AEEF);
    }
}


/* Pathways */


.pathways {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pathways .course-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #000;
}

.pathways .course-box .heading-box {
    min-width: 250px;
    height: 150px;
    border: 1px solid #ccc;
    padding: 20px;
    margin: 10px;
    position: relative;
    margin: 20px;

    border-radius: 16px;
    border: 2px solid #B9E500;
    border: 2px solid var(--primary-green, #B9E500);
    background: #D8FFAC;
    background: var(--secondary-green, #D8FFAC);
    box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;

    >h6 {
        color: #000;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}

/* div {
    border: 1px solid #ccc;
    padding: 10px;
    margin: 10px;
    position: relative;
  } */

.tab-button {
    position: absolute;
    bottom: -20px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 50%;
    width: 60px;
    height: 60px;
}

.tab-button span {
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;
    display: block;
    background-color: #ccc;
    border: 1px solid #ccc;
}

.tab-button.active span {
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;
    display: block;
    background-color: #B9E500;
    border: 1px solid #B9E500;
}

.pathways .tab-content.content-area  {
    display: none;
    padding: 20px;

}

.pathways .tab-content.content-area.active {
    display: flex;
}

.pathways .content-area {
    padding: 30px;
    background-color: #000;
    flex-direction: column;
    align-items: center;

    .view-more {
        padding: 12px 24px;
        margin: 20px 0px;
        max-width: 300px;
        border-radius: 8px;
        text-decoration: none;
        border: 1px solid #00AEEF;
        border: 1px solid var(--primary-blue, #00AEEF);
        background: #FFF;
        color: #181818;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.32px;
    }
}

.pathways .content-area .content-box {
    display: flex;
    flex-direction: row;
}

.pathways .content-area .content-box .content-card {
    border-radius: 16px;
    border: 1px solid #3F3C4D;
    background: rgba(69, 69, 69, 0.10);
    padding: 30px 20px;
    margin: 10px;
    width: calc(33%);

    >h6 {
        color: #00AEEF;
        color: var(--primary-blue, #00AEEF);
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        /* 110% */
    }

    >p {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.pathways .content-area .content-box .content-card:hover {
    border-radius: 16px;
    border: 1px solid #3F3C4D;
    background: #00AEEF;
    background: var(--primary-blue, #00AEEF);
    -webkit-backdrop-filter: blur(17px);
            backdrop-filter: blur(17px);
    display: flex;
    flex-direction: column-reverse;

    >h6 {
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
    }
}

@media (max-width: 576px) {
    .pathways .course-box {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: #000;
    }

    .pathways .course-box .heading-box {
        min-width: 300px;
        height: 100px;
        text-align: center;

        >h6 {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }

    .pathways .content-area {
        padding: 10px;
        background-color: #000;

    }

    .pathways .content-area .content-box {
        display: flex;
        flex-direction: column;
    }

    .pathways .content-area .content-box .content-card {
        border-radius: 16px;
        border: 1px solid #3F3C4D;
        background: rgba(69, 69, 69, 0.10);
        padding: 30px 20px;
        margin: 10px 0px;
        width: calc(100%);

        >h6 {
            color: #00AEEF;
            color: var(--primary-blue, #00AEEF);
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
            /* 110% */
        }

        >p {
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}
.first-about-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    height: 100%;

    >div {
        width: 70%;
        white-space: normal;
        text-overflow: initial;

        >h1 {
            color: #FFF;
            font-size: 4rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

        }

        >p {
            width: 70%;
            white-space: normal;
            text-overflow: initial;
            color: #FFF;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

}

.second-about-container {
    padding: 40px 0px;
    display: flex;
    flex-direction: row;

    .image-card {
        flex: 1 1;
        margin: 10px 30px;
        padding: 90px 30px;
        box-sizing: border-box;
        border-radius: 16px;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.80) 100%), url(<path-to-image>), lightgray 50% / cover no-repeat;
        height: 420px;

        >h6 {
            color: #B9E500;
            color: var(--primary-green, #B9E500);
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        >p {
            padding-top: 10px;
            color: #FFF;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    .image-card.top-gap {
        margin-top: 80px;
    }

}

.third-about-container {
    padding: 40px 0px;

    >h2 {
        color: #FFF;
        text-align: center;

        font-size: 3rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    >p {
        margin-top: 20px;
        color: #FFF;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}




.fourth-about-container {
    padding: 40px 0px;

    >h2 {
        color: #FFF;
        text-align: center;

        font-size: 3rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding-bottom: 40px;
    }
}


.fourth-about-container .about-cards {
    display: flex;
    flex-direction: row;

    .colorful-card {
        position: relative;
        flex: 1 1;
        margin: 10px;
        padding: 20px;
        box-sizing: border-box;
        overflow: hidden;

        /* Include padding in the width calculation */
        >h4 {
            color: #FFF;
            font-size: 2.1rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 20px;
        }

        >h3 {
            color: #FFF;
            font-size: 2rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        >p {
            color: #FFF;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 60px;
        }

        >img {
            position: absolute;
            bottom: 20px;
            left: 20px;
            max-width: 100%;
            height: auto;
        }
    }

    .colorful-card.card1 {
        border-radius: 16px;
        border: 1px solid #3F3C4D;
        background: rgba(0, 174, 239, 0.70);
    }

    .colorful-card.card2 {
        border-radius: 16px;
        border: 1px solid #3F3C4D;
        background: rgba(185, 229, 0, 0.70);
    }

    .colorful-card.card3 {
        border-radius: 16px;
        border: 1px solid #3F3C4D;
        background: rgba(102, 102, 102, 0.70);
    }

    .colorful-card.card4 {
        border-radius: 16px;
        border: 1px solid #313131;
        background: rgba(49, 49, 49, 0.70);
    }

    .colorful-card.card5 {
        border-radius: 16px;
        border: 1px solid #3F3C4D;
        background: rgba(19, 19, 19, 0.70);
    }
}

.black-input {
    background-color: #000;
    color: #707070;
}


@media screen and (max-width: 600px) {


    .first-about-container div {
        width: 100%;

        >h1 {
            text-align: center;
            font-size: 3rem !important;
        }

        >p {
            text-align: center;
            width: 100%;
        }
    }

    .second-about-container {
        flex-direction: column;

        .top-gap {
            margin-top: 10px !important;
        }
    }
    .third-about-container {
        >h2{
            font-size: 2.5rem !important;
        }
        >p{
            font-size: 1rem !important;
            padding: 0px 30px !important;

        }
    }
    .fourth-about-container{
        flex-direction: column !important;
        .about-cards{
            display: flex;
            flex-direction: column;
        }
        .colorful-card{
            min-width: 90% !important;
            margin: 10px;
        }
    }
}
.login-modal{
    /* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background for the blur effect */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Modal Content */
/* .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    position: relative;
  }
   */
/* Close Button */
.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
}


.modal-content {
    display: flex;
    width: 520px;
    padding: 110px 60px 110px 60px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 48px;
    gap: 48px;
    position: relative;
    background: linear-gradient(325deg, #00AEEF 0.74%, #B9E500 100%);
}

.modal-content {
    /* >h2 {
        color: #FFF;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .form-label{
        color: #000;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
    } */
}

.modal-overlay-admin {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 1);
    /* Semi-transparent background for the blur effect */
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content-admin {
    display: flex;
    width: 480px;
    padding: 110px 60px 110px 60px;
    flex-direction: column;
    justify-content: center;
    border-radius: 16px;
    align-items: center;
    grid-gap: 48px;
    gap: 48px;
    position: relative;
    background: rgb(43, 43, 43);
    .form-label{
        color: #FFF !important;
    }
}
}
.auth-page-section .main-container {
    display: flex;
    flex-direction: row;
    >span{
        position: relative;
        right: 0px;
        font-size: 30px;
    }
}

.auth-page-section .heading {
    padding: 10px 20px;

    >h6 {
        color: #1D1D1D;
        color: var(--Black, #1D1D1D);
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        /* 27.659px */
        letter-spacing: -0.395px;
    }

    >p {
        color: #2D3748;
        color: var(--Body-text, #2D3748);
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        /* 13.83px */
        letter-spacing: -0.198px;
    }
}

.auth-page-section .content-area {
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth-page-section .content-box {
    width: 80%;

    >img {
        width: 300px;
        height: 100px;
    }
}

.auth-page-section-signup .signup-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;

    .form-label {
        color: #2D3748;
        color: var(--Body-text, #2D3748);
        font-size: 12 px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        /* 13.83px */
        letter-spacing: -0.198px;
    }
    >button{
        color: #FFF;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.24px;
    }
}

.login-redirect {
    padding-top: 20px; 
    margin: 0px 15px; 
    border: none;
    background-color: #FFF;
    color: #2D3748;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 16.8px */
    letter-spacing: -0.24px;

    >span {
        color: #007AFF;
        color: var(--Blue, #007AFF);
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: -0.24px;
    }

}

.auth-page-section-signup .form-group {
    max-width: 50%;
    padding: 10px 20px;
    /* > input{
        min-width: 500px;
    } */
}
.auth-page-section-signup .alert{
    margin: 0px 15px;
    text-align: center;
}
.auth-page-section-signup button {
    width: 100%;
    margin: 15px;
    height: auto;
    padding: 15px;
    border: none;
    border-radius: 5px;
    background: #00AEEF;
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.24px;
}
.close-button{
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
}
.auth-page-section-login .login-box {
    display: flex;
    flex-direction: column;

    .form-label {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    >button {
        min-width: 350px;
        height: 36px;
        margin-top: 20px;
        border: none;
        border-radius: 5px;
        background: #fff;
        color: #00AEEF;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    >h6 {
        padding-top: 5px;
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: right;
    }

    >h5 {
        padding-top: 10px;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: center;

        >span {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            cursor: pointer;
        }
    }

}

.auth-page-section-login .form-group {
    min-width: 350px;
    padding-bottom: 10px;
}

.auth-page-section-login {
    .login {
        color: #FFF;
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}
.coupons.open {
    display: block;
}

.coupons.close {
    display: none;
}

.existing-coupon-box {
    display: flex;
    flex-direction: row;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background for the blur effect */
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    width: 50%;
    padding: 20px;
    align-items: normal !important;
    grid-gap: 10px !important;
    gap: 10px !important;

    .close {
        margin-left: auto !important;
        margin: 10px;
        cursor: pointer;
    }

    .coupon-input-box {
        display: flex;
        flex-direction: row;
    }

    .wrong-hide{
        display: none;
    }

    >h4 {
        color: Grey;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1;
        margin-top: 20px;

    }

    .existing-coupons {
        border-radius: 5px;
        border: 1px solid lightgray;
        .grey {
            background-color: lightgrey;
        }
        .existing-coupon-box {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            
            >div {
                margin: 5px 10px;

                >h6 {
                    color: #000;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 1;
                }

                >p {
                    color: #000;
                    font-size: 1rem;
                    font-style: normal;
                    padding-top: 5px;
                    line-height: 0;
                }
            }

            >button {
                margin: 0px 10px;
            }
        }
    }
}

.apply-button {
    margin: 20px 40px;
    border-radius: 10px;
    background: #00AEEF;
    background: var(--primary-blue, #00AEEF);
    padding: 10px 60px;
    color: #FFF;
    text-align: center;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: none;
}

.total-fee-amount{
    font-weight: 500;
    text-align: center;
    font-size: 20px;
    font-style: normal;
}
.hide-in-mobile {
    @media (max-width: 576px) {
        display: none;
    }
}

/* First section  */
.first-section {
    margin: 0px;
    position: relative;

}

.vertical-box {
    position: absolute;
    top: 50px;
    right: 100px;
    /* bottom: -1rem; */
    bottom: 0;
    width: 400px;
    border-radius: 30px 30px 0px 0px;
    background: #F5F5F5;
    background: var(--Background-accent-off, #F5F5F5);
    box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;
}

.vertical-box .image-area img {
    width: 360px;
    max-height: 280px;
    margin: 20px;
    border-radius: 30px 30px 0px 0px;
    background: url(<path-to-image>), lightgray 50% / cover no-repeat, #D9D9D9;
    box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;
}

.vertical-box .content-area {
    margin: 30px 20px;
}
.h6-text {
    margin-top: 10px;
}
.vertical-box .content-area {
    /* >img {
        width: 40px;
        height: 40px;
    } */
    .h6-text {
        margin-top: 10px;
        color: #000;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .p-text {
        color: #6F6F6F;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.first-section .first-area {
    background-color: #000;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.first-section .first-area .left-container {
    margin-left: 50px;

    >h6 {
        color: #FFF;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    >h2 {
        color: #FFF;
        font-size: 4rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    >p {
        color: #FFF;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        max-width: 90%;
    }

    >button {
        margin-top: 30px;
        border-radius: 8px;
        background: #B9E500;
        background: var(--primary-green, #B9E500);
        box-shadow: -3px -3px 5px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 5px 0px rgba(0, 0, 0, 0.25) inset;
        height: 48px;
        padding: 14px 24px;

        color: #181818;
        text-align: center;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.32px;
        border: none;
    }
}

.first-section .first-area .left-container .details-box {
    display: flex;
    flex-direction: row;

    >.details-box-div {
        border-radius: 16px;
        border: 1px solid #3F3C4D;
        background: rgba(69, 69, 69, 0.30);
        padding: 10px 20px;
        margin-right: 20px;
        >div{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            color: #FFF;
            >h5 {
                padding-top: 5px;
                color: #FFF !important;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
            }
        
        }
        

        >h4 {
            color: #FFF;
            font-size: 1.75rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}

.first-section .second-area .second-box {
    >h3 {
        margin: 40px 0px 20px;
    }
}

.first-section .second-area{
    min-height: 43rem !important;
}

@media (max-width: 576px) {
    .first-section .first-area{
        max-height: 30rem !important;
    }
    .first-section .second-area{
        max-height: 30rem !important;
    }
    .first-section .first-area .left-container {
        margin-left: 10px !important;

        >h2 {
            font-size: 1.75rem !important;
        }

        >p {
            font-size: 0.5rem !important;
        }
    }

    .first-section .first-area .left-container .details-box {
        >.details-box-div {
            >h5 {
                font-size: 0.6rem !important;
            }

            >h4 {
                font-size: 1rem !important;
            }
        }
    }

    .first-section .second-area .second-box {
        >h3 {
            font-size: 1rem;
        }

        >p {
            font-size: 0.5rem;
        }
    }
}

/* Second Section  */

.curriculum {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* align-items: center; */
    padding: 50px 0px;
}

.curriculum .col-md-4 {
    margin: 10px 0px 0px 80px;
}

.curriculum .col-md-4 img {
    max-width: calc(100% - 50px);
    border-radius: 0px 0px 16px 16px;
    box-shadow: -5px -5px 10px 0px rgba(0, 0, 0, 0.25) inset, 5px 5px 10px 0px rgba(0, 0, 0, 0.25) inset;
}

.curriculum .col-md-8 {
    padding: 0px 40px;
    /* padding: 0px 80px 0px 40px; */
}

.curriculum .col-md-8 h3 {
    color: #000;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 30px;
    margin-left: 20px;
}

.curriculum .col-md-8 .card {
    margin: 20px;
}

.curriculum .col-md-8 .card-header button {
    text-decoration: none;
    text-align: left;
    width: 100%;
}

.curriculum .col-md-8 .card-header button span {
    float: right;
}

.curriculum .card-header button {
    color: #000;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

@media (max-width: 576px) {

    .curriculum .col-md-8 h3 {
        font-size: 1.25rem;
    }

    .curriculum .col-md-8 {
        padding: 0px 5px !important;
        text-align: center;
        min-width: 100% !important;
        /* padding: 0px 80px 0px 40px; */
    }

    .curriculum .card-header button {
        font-size: 0.75rem;
    }

    .curriculum .card-body {
        font-size: 0.65rem;
    }

    .curriculum .col-md-8 .card {
        margin: 10px;
    }

    .curriculum {
        padding: 30px 0px;
    }

    .curriculum .card-header button {
        width: 100%;
    }
}

/* Third Section  */
.program-highlights {
    margin-bottom: 50px;
}



.program-highlights h2 {
    color: #000;
    text-align: center;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.program-highlights .first-row {
    margin: 20px 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.program-highlights .second-row {
    margin: 20px 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.program-highlights .first-row .highlight-card {
    height: 250px;
}

.program-highlights .second-row .highlight-card {
    min-height: 200px;
}

.program-highlights .highlight-card {
    margin: 0px 10px;
    padding: 30px;
    border-radius: 16px;
    border: 1px solid #00AEEF;
    border: 1px solid var(--primary-blue, #00AEEF);
    background: #B3E0F2;
    background: var(--secondary-blue, #B3E0F2);
    box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;

    >h6 {
        color: #070707;
        margin-top: 10px;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.48px;
    }

    >p {
        margin-top: 10px;
        color: #343434;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

@media (max-width: 576px) {
    .program-highlights h2 {
        color: #000;
        text-align: center;
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .program-highlights .first-row {
        margin: 10px 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .program-highlights .second-row {
        margin: 10px 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .program-highlights .first-row .highlight-card {
        min-width: 100%;
        margin: 10px 0px;
        height: 250px;
    }

    .program-highlights .second-row .highlight-card {
        min-width: 100%;
        margin: 10px 0px;
        height: 250px;
    }
}


/* Fifth Section  */
@media (max-width: 576px) {
    .fifth-section .first-container {
        margin: 40px 10px;
        padding: 50px 0px;
    }

    .fifth-section .first-container .first-row {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        margin: 30px 0px;
        height: 100px;
    }

    /* .pap-ninth-section .first-container .first-row img {
        max-height: 100px;
    }
    .pap-ninth-section .first-container .second-row {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        margin: 50px 0px;
        height: 80px;
    }
    .pap-ninth-section .first-container .second-row img {
        max-height: 80px;
    } */

}

.fifth-section {
    padding: 40px 0px;
}

.fifth-section .first-container {
    /* margin: 60px 40px; */
    /* padding: 50px 0px; */
    /* border-radius: 16px;
    padding: 50px 0px;
    background: var(--secondary-blue, #B3E0F2);
    box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    >img {
        max-width: 100%;
    }
}

/* .fifth-section .tools-used {
    margin: 60px 40px;
    border-radius: 16px;
    padding: 50px 0px;
    background: var(--secondary-blue, #B3E0F2);
    box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;
} */

/* .fifth-section .tools-used h2 {
    text-align: center;
    color: #070707;
    font-size: 3rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 40px 0px;
}

.fifth-section .tools-used .first-row {

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: 30px 0px;
    height: 200px;
}

.fifth-section .tools-used .first-row img {
    height: 200px;
}

.fifth-section .tools-used .second-row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: 50px 0px;
    height: 180px;
}

.fifth-section .tools-used .second-row img {
    height: 180px;
} */



/* Sixth Section  */

.sixth-section .pricing h2 {
    color: #000;
    font-size: 3rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
}

.sixth-section .pricing-box {
    display: flex;
    flex-direction: row;

}



.sixth-section .pricing-box .self-paced {
    margin: 50px 30px;
    text-align: center;
    border-radius: 16px;
    background: #B3E0F2;
    background: var(--secondary-blue, #B3E0F2);
    box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;

    >img {
        margin: 50px 0px 20px;
    }

    >h4 {
        margin: 20px 0px;
    }

    >p {
        margin: 20px 40px 80px;
    }
}

.sixth-section .pricing-box .self-paced .benefit-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;

    >p {
        margin: 0px !important;
        padding-left: 10px;
    }
}

.sixth-section .pricing-box .self-paced .self-paced-benefit {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    padding: 0px 10px;
}

.sixth-section .pricing-box .self-paced .row-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.sixth-section .pricing-box .self-paced .bottom-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 50px 30px;
    align-items: center;

    >h5 {
        color: #000;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    >button {
        border-radius: 8px;
        border: 1px solid #00AEEF;
        background: #00AEEF;
        box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;
        color: #000;
        text-align: center;
        font-size: 0.625 rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.24px;
        border: none;
        padding: 20px 32px;
    }
}



.sixth-section .pricing-box .mentor-led {
    margin: 50px 30px;
    text-align: center;
    border-radius: 16px;
    background: #D8FFAC;
    background: var(--secondary-green, #D8FFAC);
    box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;

    >img {
        margin: 50px 0px 20px;
    }

    >h4 {
        margin: 20px 0px;
    }

    >p {
        margin: 20px 40px 80px;
    }
}

.sixth-section .pricing-box .mentor-led .benefit-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;

    >p {
        margin: 0px !important;
        padding-left: 10px;
    }
}

.sixth-section .pricing-box .mentor-led .mentor-led-benefit {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    padding: 0px 10px;
}

.sixth-section .pricing-box .mentor-led .row-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}


.sixth-section .pricing-box .mentor-led .bottom-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 50px 30px;
    align-items: center;

    >h5 {
        color: #000;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    >button {
        border-radius: 8px;
        border: 1px solid #B9E500;
        border: 1px solid var(--primary-green, #B9E500);
        background: #B9E500;
        box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;
        color: #000;
        text-align: center;
        font-size: 0.625 rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.24px;
        border: none;
        padding: 20px 32px;
    }
}

@media (max-width: 576px) {
    .sixth-section .pricing-box {
        display: flex;
        flex-direction: column;

    }

    .sixth-section .pricing-box .self-paced {
        margin: 10px;
    }

    .sixth-section .pricing-box .mentor-led {
        margin: 10px;
    }

    .sixth-section .pricing-box .self-paced .bottom-box {
        padding: 10px 20px;
    }

    .sixth-section .pricing-box .mentor-led .bottom-box {
        padding: 10px 20px;
    }
}




.ceritification {
    background-color: #000;
}

.ceritification img {
    max-width: 100%;
}
.project-box{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.project-box img {
    max-width: 100%;
}
.hide-div {
  height: 0;
  opacity: 0;
  width: 0;
  overflow: hidden;
  transition: width 1s ease-in-out, opacity 1s ease-in;
}

.show-div {
  opacity: 1;
  transition: width 1s ease-in-out, opacity 1s ease-in;
}

/* First Section  */
.first-section-container {
  padding-top: 90px;
}

.first-section-container .first-card-box h1 {
  margin-top: 100px;
  color: #FFF;
  /* font-family: Raleway; */
  font-size: 4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 84px;

  /* 131.25% */
  width: 70%;
  white-space: normal;
  text-overflow: initial;
}

.first-section-container .first-card-box h4 {
  margin-top: 25px;
  color: #FFF;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 70%;
  white-space: normal;
  text-overflow: initial;
}

.first-section-container .first-card-box button {
  border-radius: 8px;
  border: 2px solid #00AEEF;
  border: 2px solid var(--primary-blue, #00AEEF);
  /* border: none; */
  background: #000;
  padding: 10px 30px;
  /* margin-top: 40px; */
  color: #FFF;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.32px;
}

.first-section-container .circle-text {
  color: #BFBFBF;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
}











.first-section-container {
  /* min-height: 30rem; */
}

.first-section-container .container-box {
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  padding: 0px;
}

.first-section-container .container-box .my-box.show-content {
  opacity: 1;
  transition: opacity 1s ease-in;
}

.first-section-container .container-box .hide-content {
  width: 0;
  height: 0;
  opacity: 0;
}

.first-section-container .container-box .my-box {
  transition: width 1s ease-in-out, opacity 1s ease-in;
}

.first-section-container .container-box .title-box .show-content-h3 {
  transition: -webkit-transform 1.5s;
  transition: transform 1.5s;
  transition: transform 1.5s, -webkit-transform 1.5s;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.first-section-container .container-box .title-box .hide-content-h3 {
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
}

.first-section-container .container-box .slide-item {
  width: 80px;
}

.first-section-container .container-box .slide-item.shift-left {
  width: 100%;
  opacity: 1;
  transition: width 1s ease-in-out, opacity 1s ease-in;
}

.first-section-container .container-box .slide-item {
  transition: width 1s ease-in-out, opacity 1s ease-in;
}

.first-section-container .content-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.first-section-container .container-box .title-box {
  min-width: 70px;
  min-height: 45rem;
  display: flex;
  padding-top: 30px;
  flex-direction: column;

  /* justify-content: center; */
  /* align-items: center; */
  >button {
    border: none;
    background-color: transparent;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
  }

  >h3 {
    margin-top: 15px;
    -webkit-writing-mode: vertical-rl;
            writing-mode: vertical-rl;
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.first-section-container .my-box {
  flex: 1 1;
  max-width: 100%;
  overflow: hidden;
}


.first-section-container .container-box .shift-left {
  margin-left: 0%;
}

/* .content-container {
  display: flex;
}

.content {
  width: 100%;
  text-align: center;
  padding: 20px;
} */


/* First section how it works content  */

.first-section-container .content-box {
  align-items: normal;

  >h2 {
    margin: 30px 0px 0px;

    >.span-1 {
      color: #00AEEF;
      color: var(--primary-blue, #00AEEF);
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    >.span-2 {
      color: #B9E500;
      color: var(--primary-green, #B9E500);
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    >.span-3 {
      color: #FFF;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  >img {
    margin: 40px 0px;
  }
}

.first-section-container .content-box .second-row-container {
  margin: 40px 0px;

  >.card-area {
    >.card-box {
      border-radius: 16px;
      border: 1px solid #B9E500;
      background: rgba(24, 24, 24, 0.70);
      padding: 25px;
      margin: 10px;
      width: calc(33.3333%);

      >h3 {
        color: #FFF;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 10px;
      }

      >p {
        color: #ABABAB;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 10px;
      }
    }
  }

  >h2 {
    color: #FFF;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: right;
  }

  >p {
    color: #FFF;
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}


.first-section-container .content-box-2 {
  background-color: #1A1A1A;
  padding: 40px 0px;

  >h2 {
    padding: 10px;
    color: #FFF;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  >p {
    padding: 30px 10px;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  >.first-row-container {
    >.card-area {
      >.card-box {
        padding: 20px;
        margin: 10px;
        border-radius: 16px;
        border: 1px solid #00AEEF;
        box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;

        >h3 {
          color: #FFF;
          /* text-align: center; */
          font-size: 40px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        >p {
          padding-top: 15px;
          color: #FFF;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        >a {
          >button {
          padding: 12px 24px;
          border-radius: 8px;
          border: 1px solid #B9E500;
          background: #FFF;

          color: #181818;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.32px;
        }
      }
      }

      >.card-box:hover {
        border-radius: 16px;
        border: 1px solid #B9E500;
        background: #D8FFAC;
        background: var(--secondary-green, #D8FFAC);
        box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;

        >h3 {
          color: #000;
          /* text-align: center; */
          font-size: 40px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        >p {
          padding-top: 15px;
          color: #000;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        >button {
          border-radius: 8px;
          border: 1px solid #B9E500;
          background: #FFF;
          padding: 12px 24px;

          color: #181818;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.32px;
        }
      }
    }
  }

  >.second-row-container {
    padding: 40px 10px;

    >h3 {
      margin: 30px 0px;
      color: #FFF;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 62px;
    }

    >p {
      color: #FFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 30px 0px;
    }

    >.second-card-box {
      border-radius: 8px;
      background: #000;
      padding: 20px 40px;
      margin: 10px 0px;

      >h6 {
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.36px;
      }

      >p {
        color: #B3B3B3;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

  }
}



/* Third section kry high lights  */

.flex-row {
  display: flex;
  flex-direction: row;
}

.content-box-3 {
  padding: 40px 10px;
  >h2 {
    padding: 0px 10px 30px;
    color: #FFF;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.content-box-3 .first-row-container p {
  margin: 30px 10px 40px;
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.content-box-3 .first-row-container .first-row .card-box-first {
  padding: 10px;
  max-width: 230px;
  max-height: 230px;
  min-height: 230px;
  margin: 10px;
  border-radius: 16px;
  border: 1px solid #00AEEF;
  border: 1px solid var(--primary-blue, #00AEEF);
  /* background: url(<path-to-image>), lightgray 50% / cover no-repeat, #181818; */

  >h3 {
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  >p {
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  >img {
    float: right;
  }
}


.content-box-3 .first-row-container .first-row .card-box-first.large {
  padding: 20px;
  min-width: 460px;
  min-height: 470px;
  margin: 10px;
  border-radius: 16px;
  border: 1px solid #00AEEF;
  border: 1px solid var(--primary-blue, #00AEEF);
  /* background: url(<path-to-image>), lightgray 50% / cover no-repeat, #181818; */

  >h3 {
    color: #FFF;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  >p {

    color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  >img {
    float: right;
  }
}

.content-box-3 .first-row-container .first-row .flex-row-wrap {
  display: flex;
  flex-wrap: wrap;
}

/* .card-box-first {
  width: 25%;
  height: 25%;
  transition: width 0.3s, height 0.3s;
} */
/*
.flex-row.large .card-box-first {
  width: 50%;
  height: 50%;
} */

/* .large-card {
  width: 50% !important;
  height: 50% !important;
}

.card-box-first {
  width: 20%;
  height: 20%;
} */




@media (max-width: 576px) {
  .first-section-container-mobile{
    padding-top: 10px;
  }
  .first-section-container-mobile .content-box {
    align-items: normal;
  
    >h2 {
      margin: 20px 0px 0px;
      >.span-1 {
        color: #00AEEF;
        color: var(--primary-blue, #00AEEF);
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
  
      >.span-2 {
        color: #B9E500;
        color: var(--primary-green, #B9E500);
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
  
      >.span-3 {
        color: #FFF;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  
    >img {
      margin: 20px 0px 40px;
    }
  }

  .second-row-container-mobile {
    margin: 40px 0px;
  
    >.card-area {
      display: flex;
      flex-direction: column !important;
      >.card-box {
        border-radius: 16px;
        border: 1px solid #B9E500;
        background: rgba(24, 24, 24, 0.70);
        margin: 10px 0px;
  
        >h3 {
          color: #FFF;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin: 10px;
        }
  
        >p {
          color: #ABABAB;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin: 10px;
        }
      }
    }
  
    >h2 {
      color: #FFF;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-align: right;
    }
  
    >p {
      color: #FFF;
      text-align: right;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  
}

.first-row-container .fourth-about-container .about-cards .colorful-card{
  padding: 10px !important;
  >h3{
    font-size: 1.75rem;
  }
}

@media (max-width: 576px) {
  .first-section-container-mobile .content-box-2 {
    background-color: #1A1A1A;
    padding: 40px 0px;
  
    >h2 {
      padding: 10px;
      color: #FFF;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  
    >p {
      padding: 30px 10px;
      color: #FFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  
    >.first-row-container {
      >.flex-row {
        flex-direction: column !important;
      }
      >.card-area {
        >.card-box {
          padding: 20px;
          margin: 10px;
          border-radius: 16px;
          border: 1px solid #00AEEF;
          box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;
  
          >h3 {
            color: #FFF;
            /* text-align: center; */
            font-size: 40px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
  
          >p {
            padding-top: 15px;
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
  
          >a {
            >button {
            padding: 12px 24px;
            border-radius: 8px;
            border: 1px solid #B9E500;
            background: #FFF;
  
            color: #181818;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.32px;
          }
        }
        }
  
        >.card-box:hover {
          border-radius: 16px;
          border: 1px solid #B9E500;
          background: #D8FFAC;
          background: var(--secondary-green, #D8FFAC);
          box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;
  
          >h3 {
            color: #000;
            /* text-align: center; */
            font-size: 40px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
  
          >p {
            padding-top: 15px;
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
  
          >button {
            border-radius: 8px;
            border: 1px solid #B9E500;
            background: #FFF;
            padding: 12px 24px;
  
            color: #181818;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.32px;
          }
        }
      }
    }
  
    >.second-row-container {
      padding: 40px 10px;
  
      >h3 {
        margin: 30px 0px;
        color: #FFF;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 62px;
      }
  
      >p {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 30px 0px;
      }
  
      >.second-card-box {
        border-radius: 8px;
        background: #000;
        padding: 20px 40px;
        margin: 10px 0px;
  
        >h6 {
          color: #FFF;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.36px;
        }
  
        >p {
          color: #B3B3B3;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
  
    }
  }
}


.explore-courses{
  background-color: #000;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  >a{
    padding: 5px 10px;
    text-decoration: none;
    color: #fff;
    width: 100%;
    border-bottom: 0.1px solid rgb(26, 26, 26);
  }

}
.explore-div{
  /* border-radius: 8px; */
  /* border: 2px solid var(--primary-blue, #00AEEF); */
  background: #000;
  margin-top: 40px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;

}
.pap-first-section .first-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 100vh;
}

@media (max-width: 576px) {

    .pap-first-section .first-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 100vh;
        padding-top: 80px;
    }

    .pap-first-section .first-container .left-container {
        margin: 0px 10px;
        padding: 40px 0px;
        min-width: 100%;

        >h2 {
            color: #FFF;
            font-size: 2rem !important;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

        }

        >h6 {
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        >p {
            color: #FFF;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    .pap-first-section .first-container .right-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 100%;
        padding: 0px 0px 40px;

        >div {
            >div {
                width: 100% !important;
                height: auto;
                border-radius: 8px;
                overflow: hidden;
                border: 1px solid #B9E500;
                border: 1px solid var(--primary-green, #B9E500);
                background: url(<path-to-image>), lightgray 50% / cover no-repeat, #D9D9D9;
                box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;

                >img {
                    /* width: 300px;
                    height: 100%; */
                    object-fit: cover;
                }
            }

            >button {
                margin-top: 20px;
                border-radius: 8px;
                border: none;
                background: #B9E500;
                box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;
                display: flex;
                width: 100% !important;
                height: 69px;
                padding: 14px 24px;
                justify-content: center;
                align-items: center;
                color: #181818;
                text-align: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 0.32px;
            }
        }
    }
}

.pap-first-section .first-container .left-container {
    margin: 0px 10px;
    width: 50%;
    >h2 {
        color: #FFF;
        font-size: 4rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

    }

    >h6 {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    >p {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    >.details-box {

        display: flex;
        flex-direction: row;

        >.details-box-div {
            border-radius: 16px;
            border: 1px solid #3F3C4D;
            background: rgba(69, 69, 69, 0.30);
            padding: 10px;
            margin-right: 20px;

            >h5 {
                color: #FFF;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
            }

            >h4 {
                color: #FFF;
                font-size: 1.75rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
}

.pap-first-section .first-container .right-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    >div {
        >div {
            width: 578px;
            height: 311px;
            border-radius: 8px;
            overflow: hidden;
            border: 1px solid #B9E500;
            border: 1px solid var(--primary-green, #B9E500);
            background: url(<path-to-image>), lightgray 50% / cover no-repeat, #D9D9D9;
            box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;

            >img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        >button {
            margin-top: 20px;
            border-radius: 8px;
            border: none;
            background: #B9E500;
            box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;
            display: flex;
            width: 577px;
            height: 69px;
            padding: 14px 24px;
            justify-content: center;
            align-items: center;
            color: #181818;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.32px;
        }
    }
}


/* second section  */

.pap-second-section .first-container {
    padding: 40px 0px;

    >h2 {
        color: #FFF;
        text-align: center;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}
@media (max-width: 576px) {
    .pap-second-section .first-container .features-box {
        display: flex;
        flex-direction: column !important;
        
        >div {
            margin: 10px !important;
        }
        .images{
            display: none;
        }
    }
}
.pap-second-section .first-container .features-box {
    display: flex;
    flex-direction: row;

    >div {
        margin: 40px;
    }
}

.pap-second-section .first-container .features-box .content-area {
    >h6 {
        padding-top: 10px;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    >p {
        padding-top: 10px;
        color: #6F6F6F;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}


/* third section  */
.pap-third-section {
    padding: 40px 0px;
}

.pap-third-section .first-container {
    /* padding: 40px 0px; */

    >h2 {
        color: #FFF;
        text-align: center;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}
.pap-third-section .content-area{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.pap-third-section .content-area .content-box {
    display: flex;
    flex-direction: row;
}

.pap-third-section .content-area .content-box .content-card {
    border-radius: 16px;
    border: 1px solid #3F3C4D;
    background: rgba(69, 69, 69, 0.10);
    padding: 30px 20px;
    margin: 10px;
    width: calc(33%);

    >h6 {
        color: #B9E500;
        color: var(--primary-green, #B9E500);
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
    }

    >p {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.pap-third-section .content-area .content-box .content-card:hover {
    border-radius: 16px;
    border: 1px solid #3F3C4D;
    background: #00AEEF;
    background: var(--primary-blue, #00AEEF);
    -webkit-backdrop-filter: blur(17px);
            backdrop-filter: blur(17px);
    display: flex;
    flex-direction: column-reverse;

    >h6 {
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
    }
}
.pap-third-section .view-more {
    
        padding: 12px 24px;
        margin: 20px 0px;
        max-width: 300px;
        border-radius: 8px;
        text-decoration: none;
        border: 1px solid #00AEEF;
        border: 1px solid var(--primary-blue, #00AEEF);
        background: #FFF;
        color: #181818;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.32px;
}
@media (max-width: 576px) {
    .pap-third-section .content-area .content-box {
        display: flex;
        flex-direction: column;
    }
    .pap-third-section .content-area .content-box .content-card {
        background-color: #00AEEF;
        min-width: calc(100% - 20px);
    }
}

/* FOurth section  */

.pap-fourth-section .first-container {
    padding: 40px 20px;


    >button {
        padding: 12px 24px;
        border-radius: 8px;
        border: 1px solid #00AEEF;
        border: 1px solid var(--primary-blue, #00AEEF);
        background: #FFF;
        color: #181818;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.32px;
        margin-left: 42%;
        position: relative;
        top: -60px;
        /* margin-right: 50%; */
    }
    >h2{
        color: #FFF;
        text-align: center;
        font-size: 3rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}

.pap-fourth-section {
    padding: 40px 0px;
}

.pap-fourth-section .first-container {
    /* background-color: #FFF; */
    position: relative;
    height: 180vh;
    width: 100%;

    /* margin: 40px 0px; */
    >img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-height: 180vh;
        object-fit: cover;
        /* Ensure the image covers the entire container */
        z-index: 0;
        /* Move the image to the background */
    }

}

.pap-fourth-section .first-container .content-area {
    margin-top: 50px;
}

.pap-fourth-section .first-container .content-area .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    /* margin-top: 100px; */
}

.pap-fourth-section .first-container .max-content-box {
    height: 280px;
    margin-top: 40px;

    >.second-content-box {
        display: none;
    }
}

.pap-fourth-section .first-container .max-content-box:hover {
    >.second-content-box {
        display: block;
    }

    >.content-box {
        display: none;
    }
}

.pap-fourth-section .first-container .second-content-box {
    position: relative;
    padding: 25px;
    width: 350px;
    height: 280px;
    border-radius: 16px;
    border-bottom: 3px solid #B9E500;
    border-bottom: 3px solid var(--primary-green, #B9E500);
    background: #F7F7F7;
    background: var(--White, #F7F7F7);
    z-index: 2;

    >h6 {
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    >p {
        margin-top: 20px;
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.pap-fourth-section .first-container .content-box {
    position: relative;
    padding: 20px;
    z-index: 1;
    height: 140px;
    width: 350px;
    border-radius: 16px;
    border-bottom: 3px solid #B9E500;
    border-bottom: 3px solid var(--primary-green, #B9E500);
    background: #454444;

    /* Bring the content boxes to the foreground */
    >p {
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.28px;
    }

    >h6 {
        color: #FFF;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}


@media (max-width: 576px) {
    .pap-fourth-section .first-container {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .pap-fourth-section .first-container .content-area {
        margin-top: 0px;
    }
    .pap-fourth-section .first-container button{
        margin: 0px;
        top: 40px;
    }
    .pap-fourth-section .first-container .images{
        display: none;
    }
    .pap-fourth-section .first-container .content-area{
        display: flex;
        flex-direction: column;
    }
    .pap-fourth-section .first-container .content-area .flex-row {
        flex-direction: column !important;
    }
    .pap-fourth-section .first-container .max-content-box {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }

}

/* fifth section  */
.pap-fifth-section .first-container {
    display: flex;
    flex-direction: row;
    padding: 40px 20px;

    >div {
        margin: 0px 20px;

        >h2 {
            color: #FFF;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        >p {
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding-bottom: 30px;
        }
    }
}

@media (max-width: 576px) {
    .pap-fifth-section .first-container {
        display: flex;
        flex-direction: column-reverse;
        padding: 40px 10px;
    
        >div {
            margin: 0px 10px 40px 10px;
    
            >h2 {
                color: #FFF;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
    
            >p {
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                padding-bottom: 30px;
            }
            >img{
                max-width: 100%;
            }
        }
    }
}

/* sixth section  */
.pap-sixth-section {

    >h2 {
        color: #FFF;
        text-align: center;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding: 40px 0px;
    }
}

.item1 {
    grid-area: first;
}

.item2 {
    grid-area: second;
}

.item3 {
    grid-area: third;
}

.item4 {
    grid-area: fourth;
}

.item5 {
    grid-area: fifth;
}

.item6 {
    grid-area: sixth;
}

.item7 {
    grid-area: seventh;
}

.item8 {
    grid-area: eighth;
}
.item9 {
    grid-area: ninth;
}


.pap-sixth-section .grid-container {
    display: grid;
    grid-template-areas:
        'first second second second third third'
        'first fourth fourth fifth third third'
        'seventh fourth fourth sixth sixth sixth'
        'seventh eighth eighth eighth eighth eighth'
        'ninth ninth ninth ninth ninth ninth';
    grid-gap: 10px;
    gap: 10px;
    padding: 10px;
}

.pap-sixth-section .item-box {
    border-radius: 16px;
    border: 1px solid #B9E500;
    border: 1px solid var(--primary-green, #B9E500);
    background: rgba(24, 24, 24, 0.80);
    -webkit-backdrop-filter: blur(17px);
            backdrop-filter: blur(17px);
    padding: 20px;

    >h2 {
        color: #FFF;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    >p {
        color: #9A9A9A;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}
@media (max-width: 576px) {
    .pap-sixth-section .grid-container {
        display: flex;
        flex-direction: column;
        .item5{
            display: none;
        }
    }
    .pap-sixth-section .item-box {
        >h2{
            font-size: 1.25rem;
        }
        >p{
            font-size: 1rem;
        }
    }

}


/* eight section */
.pap-eight-section {
    margin: 60px 10px;

    >h2 {
        color: #FFF;
        text-align: center;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}

.pap-eight-section .first-container {
    display: flex;
    flex-direction: row;
    margin: 40px 10px;

    >div {
        margin: 0px 20px;
        >img{
            max-width: 100%;
        }
    }

    .details-box {
        >h2 {
            margin: 40px 0px 10px;
        }
    }
}

@media (max-width: 576px) {
    .pap-eight-section .first-container {
        display: flex;
        flex-direction: column;
        margin: 40px 0px;
    
        >div {
            margin: 0px ;
            >img{
                max-width: 100%;
            }
        }
        .images{
            display: none;
        }
    
        .details-box {
            >h2 {
                margin: 40px 0px 10px;
            }
        }
    }
}
/* ninth section  */

.pap-ninth-section {
    padding: 40px 0px;
}

.pap-ninth-section .first-container {
    /* margin: 60px 40px; */
    /* padding: 50px 0px; */
    border-radius: 16px;
    border: 1px solid #B9E500;
    border: 1px solid var(--primary-green, #B9E500);
    background: #F6F6F6;
    -webkit-backdrop-filter: blur(17px);
            backdrop-filter: blur(17px);
    >img{
        max-width: 100%;
    }
}

.pap-ninth-section .first-container h2 {
    text-align: center;
    color: #000;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.pap-ninth-section .first-container .first-row {

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: 30px 0px;
    height: 200px;
}

.pap-ninth-section .first-container .first-row img {
    max-height: 200px;
    width: auto;
}

.pap-ninth-section .first-container .second-row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: 50px 0px;
    height: 180px;
}

.pap-ninth-section .first-container .second-row img {
    max-height: 180px;
    width: auto;
}

@media (max-width: 576px) {
    .pap-ninth-section .first-container {
        margin: 40px 10px;
        padding: 50px 0px;
    }
    .pap-ninth-section .first-container .first-row {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        margin: 30px 0px;
        height: 100px;
    }
    .pap-ninth-section .first-container .first-row img {
        max-height: 100px;
    }
    .pap-ninth-section .first-container .second-row {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        margin: 50px 0px;
        height: 80px;
    }
    .pap-ninth-section .first-container .second-row img {
        max-height: 80px;
    }
    
}


/* tenth section  */
.pap-tenth-section .first-container {

    /* margin: 40px 0px; */
    >img {
        max-width: 100%;
    }
}

/* ELeventh Section  */
.pap-eleventh-section {
    padding: 40px 0px;
}

.pap-eleventh-section .pricing h2 {
    color: #FFF;
    font-size: 3rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
}

.pap-eleventh-section .pricing-box {
    display: flex;
    flex-direction: row;

}

.pap-eleventh-section .pricing-box .basic {
    margin: 50px;
    text-align: center;
    border-radius: 16px;
    background: #000;
    box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;

    >img {
        margin: 50px 0px 20px;
    }

    >h4 {
        margin: 20px 0px;
        color: #FFF;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    >p {
        margin: 20px 40px 50px;
        color: #FFF;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    >hr {
        background: #FFF;
        margin: 10px 20px;
    }
}

.pap-eleventh-section .pricing-box .basic .benefit-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
    margin: 10px 0px;

    >p {
        margin: 0px !important;
        padding-left: 10px;
        color: #FFF;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.pap-eleventh-section .pricing-box .basic .row-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.pap-eleventh-section .pricing-box .basic .basic-benefit {
    display: flex;
    flex-direction: column;
    padding: 0px 10px;

}

.pap-eleventh-section .pricing-box .basic .bottom-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 50px 30px;
    align-items: center;

    >h5 {
        color: #FFF;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 200;
        line-height: normal;
    }

    >button {
        border-radius: 8px;
        border: 1px solid #00AEEF;
        background: #00AEEF;
        box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;
        color: #FFF;
        text-align: center;
        font-size: 0.625 rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.24px;
        border: none;
        padding: 20px 32px;
    }
}



.pap-eleventh-section .pricing-box .mentor-led {
    margin: 50px;
    text-align: center;
    border-radius: 16px;
    background: #000;
    box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;

    >img {
        margin: 50px 0px 20px;
    }

    >h4 {
        margin: 20px 0px;
        color: #FFF;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    >p {
        margin: 20px 40px 50px;
        color: #FFF;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    >hr {
        background: #FFF;
        margin: 10px 20px;
    }
}

.pap-eleventh-section .pricing-box .mentor-led .benefit-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
    margin: 10px 0px;

    >p {
        margin: 0px !important;
        padding-left: 10px;
        color: #FFF;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

}

.pap-eleventh-section .pricing-box .mentor-led .row-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.pap-eleventh-section .pricing-box .mentor-led .mentor-led-benefit {
    display: flex;
    flex-direction: column;
    padding: 0px 10px;
}

.pap-eleventh-section .pricing-box .mentor-led .bottom-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 50px 30px;
    align-items: center;

    >h5 {
        color: #FFF;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 200;
        line-height: normal;
    }

    >button {
        border-radius: 8px;
        border: 1px solid #B9E500;
        border: 1px solid var(--primary-green, #B9E500);
        background: #B9E500;
        box-shadow: -3px -3px 10px 0px rgba(0, 0, 0, 0.25) inset, 3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset;
        color: #FFF;
        text-align: center;
        font-size: 0.625 rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.24px;
        border: none;
        padding: 20px 32px;
    }
}


@media (max-width: 576px) {
    .pap-eleventh-section .pricing-box {
        display: flex;
        flex-direction: column;
    
    }
    .pap-eleventh-section .pricing-box .basic {
        margin: 10px;
    }
    .pap-eleventh-section .pricing-box .mentor-led {
        margin: 10px;
    }

    .pap-eleventh-section .pricing-box .basic .bottom-box {
        padding: 10px 20px;
    }

    .pap-eleventh-section .pricing-box .mentor-led .bottom-box {
        padding: 10px 20px;
    }
}



/* twelfth section */

.pap-twelfth-section {
    margin: 40px 0px;
}

.pap-twelfth-section .first-container {
    display: flex;
    flex-direction: row;

    >div {
        margin: 20px;

        >h2 {
            color: #FFF;
            font-size: 48px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        >p {
            margin-top: 20px;
            color: #B3B3B3;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        >button {
            margin-top: 20px;
            border-radius: 8px;
            border: 1px solid #00BCBD;
            background: #000;
            padding: 20px 40px;
            color: #DAEBE8;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.32px;
        }

        >img {
            width: 300px;
            height: 380px;
            border-radius: 15px;
            border: 1px solid #000;
        }

        >h6 {
            color: #FFF;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }
}
.first-blogs-section .blog-area.hide-blog{
    display: none;
}
.first-blogs-section .blog-area {
    margin: 40px 0px;
    .blogs-flex-row{
        display: flex;
        flex-direction: row;
    }
    .content-image{
        max-width: 60%;
        >img{
            border-radius: 16px;
            max-width: 100%;
        }
    }
    .content {
        width: 40%;
        >h6 {
            color: #FFF;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            border-radius: 8px;
            border: 0px solid #B9E500;
            border: 0px solid var(--primary-green, #B9E500);
            background: #B9E500;
            background: var(--primary-green, #B9E500);
            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: fit-content;
            padding: 5px 10px;
        }

        >h5 {
            color: #181818;
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        >h4 {
            color: #181818;
            font-size: 36px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        >p {
            margin: 20px 0px;
            color: #707070;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 136%;
            /* 19.04px */
        }

        .read-now {
            font-size: 1rem;
            border-radius: 8px;
            border: 1px solid #B9E500;
            border: 1px solid var(--primary-green, #B9E500);
            background: #FFF;
            color: #181818;
            text-align: center;
            padding: 14px 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.32px;
        }
    }

    .slide-button {
        display: flex;
        flex-direction: row;
        justify-content: right;
        align-items: center;
        margin: 20px;

        >button {
            margin: 5px;
            border-radius: 8px;
            padding: 5px 10px;
            border: 2px solid #B9E500;
            border: 2px solid var(--primary-green, #B9E500);
            background: rgba(0, 0, 0, 0.00);
        }

        >div {
            margin: 5px;
            height: 5px;
            width: 5px;
            padding: 5px;
            border-radius: 50%;
            background-color: #707070;
        }

        >div.selected {
            background-color: #B9E500;
            background-color: var(--primary-green, #B9E500);
        }
    }

}

@media (max-width: 576px) {

    .first-blogs-section .blog-area {
        margin: 40px 0px;
        .blogs-flex-row{
            display: flex;
            flex-direction: column-reverse;
        }
        
        .content {
            width: 100%;
            margin: 10px 0px;
            >h6 {
                margin-top: 10px;
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                border-radius: 8px;
                border: 0px solid #B9E500;
                border: 0px solid var(--primary-green, #B9E500);
                background: #B9E500;
                background: var(--primary-green, #B9E500);
                width: -webkit-fit-content;
                width: -moz-fit-content;
                width: fit-content;
                padding: 5px 10px;
            }
    
            >h5 {
                color: #181818;
                font-size: 36px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
    
            >h4 {
                color: #181818;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
    
            >p {
                margin: 20px 0px;
                color: #707070;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 136%;
                /* 19.04px */
            }
    
            .read-now {
                font-size: 1rem;
                border-radius: 8px;
                border: 1px solid #B9E500;
                border: 1px solid var(--primary-green, #B9E500);
                background: #FFF;
                color: #181818;
                text-align: center;
                padding: 14px 24px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 0.32px;
            }
        }
        .content-image{
            max-width: 100%;
            >img{
                width: 100%;
            }
        }
    
        .slide-button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin: 20px;
    
            >button {
                margin: 5px;
                border-radius: 8px;
                padding: 5px 10px;
                border: 2px solid #B9E500;
                border: 2px solid var(--primary-green, #B9E500);
                background: rgba(0, 0, 0, 0.00);
            }
    
            >div {
                margin: 5px;
                height: 5px;
                width: 5px;
                padding: 5px;
                border-radius: 50%;
                background-color: #707070;
            }
    
            >div.selected {
                background-color: #B9E500;
                background-color: var(--primary-green, #B9E500);
            }
        }
    
    }

}



.events-section {
    padding: 40px 0px;
}

.events-section .events-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.events-section .container {
    >h2 {
        padding: 10px;
        color: #181818;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 62px;
    }
}

.events-section .event {
    border: 1px solid #ccc;
    border-radius: 16px;
    /* padding: 16px; */
    /* margin: 16px; */
    width: calc(33.33% - 32px);
    /* Adjust the width based on your layout */
    box-sizing: border-box;
    overflow: hidden;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;

}

.events-section .event:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

.events-section .event {
    margin: 30px 0px;

    .event-content {
        padding: 10px;
        margin-top: 12px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        >h3 {
            margin-bottom: 8px;
            color: #181818;
            font-size: 28px;
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: 0.56px;
        }

        >button {
            border-radius: 8px;
            border: 1px solid #B9E500;
            background: #FFF;
            color: #181818;
            text-align: center;
            padding: 14px 24px;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.32px;
            cursor: pointer;
        }
    }

    >p {
        padding: 10px;
        color: #707070;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }

}
.events-section .event .event-top{
    position: relative;
    .event-image{
        border-radius: 16px 16px 0px 0px;
        object-fit: cover;
        width: calc(100%);
    }
}
.event-datetime{
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    padding: 2px 4px;
    top: 0.5rem;
    right: 0.5rem;
    color: #000;
    font-weight: bold;
    border-radius: 8px;
}
.events-container .show-more-events {
    border-radius: 8px;
    border: 1px solid #00AEEF;
    border: 1px solid var(--primary-blue, #00AEEF);
    background: #FFF;
    color: #181818;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;
    padding: 14px 24px;
}

@media (max-width: 576px) {
    .events-section .events-container {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
    }
    .events-section .container {
        >h2 {
            padding: 10px;
            color: #181818;
            font-size: 36px !important;
            font-style: normal;
            font-weight: 600;
            line-height: 62px;
        }
    }
    .events-section .event {
        margin: 30px 0px;
        border: 1px solid #ccc;
        border-radius: 16px;
        width: calc(100% - 20px);
        box-sizing: border-box;
        overflow: hidden;
        transition: -webkit-transform 0.3s ease-in-out;
        transition: transform 0.3s ease-in-out;
        transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    
        .event-content {
            padding: 10px;
            margin-top: 12px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
    
            >h3 {
                margin-bottom: 8px;
                color: #181818;
                font-size: 28px;
                font-style: normal;
                font-weight: 600;
                line-height: 32px;
                letter-spacing: 0.56px;
            }
    
            >button {
                border-radius: 8px;
                border: 1px solid #B9E500;
                background: #FFF;
                color: #181818;
                text-align: center;
                padding: 14px 24px;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 0.32px;
                cursor: pointer;
            }
        }
    
        >p {
            padding: 10px;
            color: #707070;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
        }
    
    }
    .event-datetime{
        background-color: rgba(255, 255, 255, 0.5);
        position: absolute;
        padding: 2px 4px;
        top: 0.5rem;
        right: 0.5rem;
        color: #000;
        font-weight: bold;
        border-radius: 8px;
    }
}

/* Add more styles as needed */


.blogs-section {
    padding: 40px 0px;
}

.blogs-section .container {
    /* max-width: 1200px; */
    margin: 0 auto;

    >h2 {
        padding: 10px 20px;
        color: #000;
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}

.blogs-section .blogs-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.blogs-section .blog {
    padding: 10px;
    width: 23%;
    /* Adjust as needed to fit 4 events in a row */
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;

    >img {
        width: 100%;
        height: auto;
        border-bottom: 1px solid #ddd;
        /* Optional border between image and content */
    }


    >h6 {
        padding-top: 10px;
        color: #B9E500;
        color: var(--primary-green, #B9E500);
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 130.5%;
        /* 13.05px */
        text-transform: uppercase;
    }

    >h2 {
        color: #191919;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 136%;
        /* 21.76px */
    }


    >button {
        color: #181818;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 136%;
        /* 16.32px */
        border: none;
        background-color: #FFF;
    }
}

.blogs-section .blog:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

.blogs-section .show-more-blogs {
    padding: 14px 24px;
    color: #181818;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;
    border-radius: 8px;
    border: 1px solid #00AEEF;
    border: 1px solid var(--primary-blue, #00AEEF);
    background: #FFF;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

@media (max-width: 576px) {

    .blogs-section .blogs-container {
        display: flex;
        flex-direction: column !important;
        justify-content: center;
        align-items: center;
    }
    
    .blogs-section .blog {
        padding: 10px;
        width: calc(100% - 20px);
        margin-bottom: 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        overflow: hidden;
        transition: -webkit-transform 0.3s ease-in-out;
        transition: transform 0.3s ease-in-out;
        transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    
        >img {
            width: 100%;
            height: auto;
            border-bottom: 1px solid #ddd;
        }
    
        >h6 {
            padding-top: 10px;
            color: #B9E500;
            color: var(--primary-green, #B9E500);
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 130.5%;
            text-transform: uppercase;
        }
    
        >h2 {
            color: #191919;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 136%;
        }
    
        >button {
            color: #181818;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 136%;
            border: none;
            background-color: #FFF;
        }
    }

}

/* Add more styles as needed */



.popular-blogs-section {
    padding: 40px 0px;
}

.popular-blogs-section .container {
    /* max-width: 1200px; */
    margin: 0 auto;

    >h2 {
        padding: 10px 20px;
        color: #000;
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}

.popular-blogs-section .blogs-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.popular-blogs-section .blog {
    padding: 10px;
    width: 23%;
    /* Adjust as needed to fit 4 events in a row */
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;

    >img {
        width: 100%;
        height: auto;
        border-bottom: 1px solid #ddd;
        /* Optional border between image and content */
    }

    >h6 {
        padding-top: 10px;
        color: #B9E500;
        color: var(--primary-green, #B9E500);
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 130.5%;
        /* 13.05px */
        text-transform: uppercase;
    }

    >h2 {
        color: #191919;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 136%;
        /* 21.76px */
    }

    >button {
        color: #181818 !important;
        text-decoration: none;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 136%;
        /* 16.32px */
        border: none;
        background-color: #FFF;
    }
}

.read-more {
    color: #181818 !important;
    text-decoration: none;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 136%;
    /* 16.32px */
    border: none;
    background-color: #FFF;
}

.popular-blogs-section .blog:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

.popular-blogs-section .show-more-blogs {
    padding: 14px 24px;
    color: #181818;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;
    border-radius: 8px;
    border: 1px solid #00AEEF;
    border: 1px solid var(--primary-blue, #00AEEF);
    background: #FFF;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

@media (max-width: 576px) {

    .popular-blogs-section .blogs-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .popular-blogs-section .blog {
        padding: 10px;
        width: calc(100% - 20px);
        /* Adjust as needed to fit 4 events in a row */
        margin-bottom: 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        overflow: hidden;
        transition: -webkit-transform 0.3s ease-in-out;
        transition: transform 0.3s ease-in-out;
        transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    
        >img {
            width: 100%;
            height: auto;
            border-bottom: 1px solid #ddd;
            /* Optional border between image and content */
        }
    
        >h6 {
            padding-top: 10px;
            color: #B9E500;
            color: var(--primary-green, #B9E500);
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 130.5%;
            /* 13.05px */
            text-transform: uppercase;
        }
    
        >h2 {
            color: #191919;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 136%;
            /* 21.76px */
        }
    
        >button {
            color: #181818 !important;
            text-decoration: none;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 136%;
            /* 16.32px */
            border: none;
            background-color: #FFF;
        }
    }
}
/* Blog  */
.blog-page-section {
    padding: 40px 0px;
}

.blog-page-section .blog {
    width: 100%;

    >h6 {
        color: #00AEEF;
        color: var(--primary-blue, #00AEEF);
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 156%;
        /* 24.96px */
        text-transform: uppercase;
    }

    >h2 {
        color: #000;
        font-size: 3rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    >img {
        margin: 10px 0px;
        border-radius: 8px;
        /* background: url(<path-to-image>), lightgray 0px -71.792px / 100% 123.251% no-repeat; */
        max-width: 100%;
    }

    >div {
        padding: 40px 0px;

        >img {
            width: 60px;
            height: 60px;
            flex-shrink: 0;
        }

        >h4 {
            color: #707070;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 136%;
            margin-left: 10px;
            /* 21.76px */
            text-transform: uppercase;
        }

        >h5 {
            color: #707070;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 136%;
            text-transform: uppercase;
        }
    }

    >h4 {
        padding: 15px 0px;
        color: #181818;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        /* 116.667% */
    }
}

.blog-page-section .flex-row {
    align-items: center;
}

@media (max-width: 576px) {
    .blog-page-section .blog {
        width: 100%;
    
        >h6 {
            color: #00AEEF;
            color: var(--primary-blue, #00AEEF);
            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            line-height: 156%;
            /* 24.96px */
            text-transform: uppercase;
        }
    
        >h2 {
            color: #000;
            font-size: 2rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

    }
}
.first-corporate-section .container {
    height: 45rem;
    /* margin-bottom: 100px; */

    >.flex-row {
        align-items: center;
        justify-content: space-around;
    }
}

.first-corporate-section .container .content-section {
    >h1 {
        max-width: 550px;
        color: #FFF;
        font-size: 64px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}


.first-corporate-section .container .image-section {
    height: 45rem;
    >img {
        max-height: 80%;
    }
}



.second-corporate-section .container {
    padding: 40px 0px;

    >h3 {
        color: #181818;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 62px;
        /* 129.167% */
    }

    >h6 {
        padding: 20px 0px 40px;
        color: #181818;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.second-corporate-section .container .content-area {
    display: flex;
    flex-direction: row;
}
.second-corporate-section .container .content-area{
    max-width: 100%;
    >img{
        max-width: calc(50% - 20px);
        margin: 0px 10px;
    }
}
.second-corporate-section .container .content-area .content-box {
    /* max-width: 100%;
    display: flex;

    flex-direction: row;
    flex: 1; */
    width: 100%;
    >img{
        max-width: 100%;
    }
    >h4 {
        padding: 10px;
        color: #181818;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 62px;
        /* 129.167% */
    }

    >div {
        padding: 20px 10px;
        display: flex;
        flex-direction: row;
        >img{
            height: 3rem;
            width: 3rem;
        }

        /* justify-content: space-around; */
        >div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 10px;

            >h6 {
                color: #181818;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 28px;
                /* 116.667% */
            }

            >p {
                color: #565656;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }

}

@media (max-width: 576px) {

    .first-corporate-section .container .content-section {
        margin-top: 200px;
        margin-left: auto;
        margin-right: auto;

        >h1 {
            max-width: 550px;
            color: #FFF;
            font-size: 3rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }

    .second-corporate-section .container {
        padding: 40px 0px;

        >h3 {
            color: #181818;
            font-size: 48px;
            font-style: normal;
            font-weight: 600;
            line-height: 62px;
            /* 129.167% */
            text-align: center;
        }

        >h6 {
            padding: 20px 20px 40px;
            color: #181818;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    .second-corporate-section .container .content-area{
        max-width: 100%;
        >img{
            max-width: calc(100% - 20px);
            margin: 0px 10px;
        }
    }

}
.third-corporate-section h2 {
    color: #181818;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 62px;
    /* 129.167% */
}
.third-corporate-section {
    background: #B3E0F2;
    background: var(--secondary-blue, #B3E0F2);

    .container {
        padding: 50px 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .form-box {
        padding-top: 50px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    >h2 {
        color: #181818;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 62px;
        /* 129.167% */
    }

    .form-group {
        min-width: 50%;
        padding: 0px 30px;
    }

    .form-label {
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        /* 320% */
        margin-top: 40px;
    }

    .apply-button {
        margin: 50px 40px 40px;
        border-radius: 10px;
        background: #00AEEF;
        background: var(--primary-blue, #00AEEF);
        padding: 10px 60px;
        color: #FFF;
        text-align: center;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        border: none;
    }
}

@media (max-width: 576px) {
    .third-corporate-section {
        .form-group {
            min-width: 100%;
            /* padding: 0px 30px; */
        }
    }
}

@media (max-width: 576px) {
    .show-in-mobile {
        display: flex !important;
    }

    .hide-in-mobile {
        display: none;
    }

}

.hide-in-web{
    display: none;
}
.hide{
    display: none !important;
}
.show{
    display: flex;
}




.sidebar-container {
    position: absolute;
    top: 50px;
    left: -280px;
    /* height: 100%; */
    overflow-x: hidden;
    transition: left 0.3s ease;
    z-index: 1000;
}

.sidebar-container.open {
    left: 0; 
}

/* Add any other styles you need for your sidebar */


/* Add any other styles you need for your sidebar */



/* Blog */

.add-blog .content-box{
    border: 1px solid grey;
    border-radius: 8px;
    /* display: flex;
    flex-direction: row; */
    margin: 20px;
    padding: 10px;
    /* justify-content: space-around; */
}
.add-blog .blog-flex-row{
    background-color: aqua;
    /* display: flex;
    flex-direction: row; */
}
.add-blog .form-group{
    margin: 20px;
}
.add-blog .form-check{
    margin: 20px;
}


/* Events */
.add-event .content-box{
    border: 1px solid grey;
    border-radius: 8px;
    /* display: flex;
    flex-direction: row; */
    margin: 20px;
    padding: 10px;
    /* justify-content: space-around; */
}
.add-event .event-flex-row{
    background-color: aqua;
    /* display: flex;
    flex-direction: row; */
}
.add-event .form-group{
    margin: 20px;
}
.add-event .form-check{
    margin: 20px;
}

    /* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background for the blur effect */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Modal Content */
/* .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    position: relative;
  }
   */
/* Close Button */
.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
}


.modal-content {
    display: flex;
    width: 520px;
    padding: 110px 60px 110px 60px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 48px;
    gap: 48px;
    position: relative;
    background: linear-gradient(325deg, #00AEEF 0.74%, #B9E500 100%);
}

.modal-content {
    /* >h2 {
        color: #FFF;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .form-label{
        color: #000;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
    } */
}

.modal-overlay-admin {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 1);
    /* Semi-transparent background for the blur effect */
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content-admin {
    display: flex;
    width: 480px;
    padding: 110px 60px 110px 60px;
    flex-direction: column;
    justify-content: center;
    border-radius: 16px;
    align-items: center;
    grid-gap: 48px;
    gap: 48px;
    position: relative;
    background: rgb(43, 43, 43);
    .form-label{
        color: #FFF !important;
    }
}

