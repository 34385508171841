.navbar-nav li:hover>ul.dropdown-menu {
  display: block;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu>.dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
}

.black-navbar .dropdown-item:hover{
  background-color: #555;
}

@media screen and (max-width: 600px) {

  .dropdown-submenu>.dropdown-menu {
    top: 10;
    /* left: 100%; */
    /* margin-top: -6px; */
  }
}

.navbar-light .dropdown-menu {
  padding: 20px 10px;
  border-radius: 15px;
  border-bottom: 2px solid #B9E500;
  background: #FFF;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.25);

  >li {
    padding: 5px 0px;
  }

}

/* rotate caret on hover */
.dropdown-menu>li>a:hover:after {
  text-decoration: underline;
  transform: rotate(-90deg);
}

/* Navbar.css */

/* Navigation bar styles */
.navbar {
  background-color: #ffffff !important;
  /* overflow: hidden; */
  padding: 10px 0px;
  /* display: flex;
    justify-content: space-between;
    align-items: center; */
  color: #000;

  /* font-family: Raleway; */
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}


#navbarSupportedContent ul {
  align-items: center;
  >li {
    /* margin-left: 1rem;
    margin-right: 1rem; */
  }
}

.navbar-li {
  /* margin-left: 0.6rem;
  margin-right: 0.6rem; */
}
@media screen and (min-width: 600px) {
.navbar-nav-ul{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

}

.navbar-dark .dropdown-menu {
  background-color: #000;
  padding: 20px 10px;
  border-radius: 15px;
  border-bottom: 2px solid #B9E500;
  background: rgb(43, 43, 43);
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.25);

  >li {
    padding: 5px 0px;
  }
}

#navbarSupportedContent li a {
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* Logo styles */
.logo {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
}

.dropdown-flex {
  display: flex;
  flex-direction: row;
}

.logo img {
  width: 160px;
  height: 46px;
  flex-shrink: 0;
}

/* Navigation links styles */
.nav-links {
  display: flex;
}

.navbar a {
  color: #000000;
  text-decoration: none;
  transition: background-color 0.3s;
}

.nav-links a:hover {
  background-color: #555;
}

/* Sign-up button styles */
.signup-btn {
  /* color: white;
    text-decoration: none;
    padding: 10px 20px;
    background-color: #4CAF50;
    border-radius: 5px;
    transition: background-color 0.3s; */
  display: flex;
  width: 134px;
  height: 44px;
  padding: 14px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #00AEEF;
  color: #FFF !important;
  text-align: center;
  /* font-family: Poppins; */
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.32px;
}

.header-logo {
  height: 60px;
  width: 170px;
}

/* .signup-btn:hover {
    background-color: #45a049;
  } */

/* Responsive styles */
@media screen and (max-width: 600px) {
  .navbar {
    flex-direction: row;
    align-items: center;
    padding: 10px !important;
  }

  .nav-links {
    margin-top: 10px;
    justify-content: flex-start;
  }

  .nav-links a {
    margin: 0;
    width: 100%;
    box-sizing: border-box;
  }
}

.black-navbar {
  background-color: rgb(0, 0, 0, 0) !important;
}

.black-navbar #navbarSupportedContent li a {
  color: #FFF !important;
}

.black-navbar .signup-btn {
  border-radius: 8px;
  border: 1px solid var(--primary-blue, #00AEEF);
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.32px;
}
@media screen and (min-width:600px) {
  #navbarBlack {
    position: absolute;
    z-index: 999999;
  }
}


@media screen and (max-width: 600px) {
  #navbarSupportedContent{
    justify-content: center;
    align-items: center;
  }
  .navbar-nav{
    >li{
      padding: 5px !important;
      margin: 0px !important;
    }
    /* background-color: #FFF !important; */
    /* position: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between; */
  }
}

.login-profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  >h6 {
    padding-top: 0.25rem;
    padding-left: 0.5rem;
    text-transform: capitalize;
    color: #000;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;
  }
}
.login-profile-dark {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  >h6 {
    padding-top: 0.25rem;
    padding-left: 0.5rem;
    text-transform: capitalize;
    color: #FFF;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;
  }
}